var e,
  t = "undefined" != typeof window ? window : void 0,
  i = "undefined" != typeof globalThis ? globalThis : t,
  s = Array.prototype,
  r = s.forEach,
  n = s.indexOf,
  o = null == i ? void 0 : i.navigator,
  a = null == i ? void 0 : i.document,
  l = null == i ? void 0 : i.location,
  c = null == i ? void 0 : i.fetch,
  u = null != i && i.XMLHttpRequest && "withCredentials" in new i.XMLHttpRequest() ? i.XMLHttpRequest : void 0,
  d = null == i ? void 0 : i.AbortController,
  h = null == o ? void 0 : o.userAgent,
  _ = null != t ? t : {},
  p = {
    DEBUG: !1,
    LIB_VERSION: "1.229.2"
  },
  g = "$copy_autocapture",
  v = ["$snapshot", "$pageview", "$pageleave", "$set", "survey dismissed", "survey sent", "survey shown", "$identify", "$groupidentify", "$create_alias", "$$client_ingestion_warning", "$web_experiment_applied", "$feature_enrollment_update", "$feature_flag_called"];
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(e || (e = {}));
var f = ["fatal", "error", "warning", "log", "info", "debug"];
function m(e, t) {
  return -1 !== e.indexOf(t);
}
var y = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  },
  b = function (e) {
    return e.replace(/^\$/, "");
  };
var w = Array.isArray,
  S = Object.prototype,
  k = S.hasOwnProperty,
  E = S.toString,
  x = w || function (e) {
    return "[object Array]" === E.call(e);
  },
  I = e => "function" == typeof e,
  P = e => e === Object(e) && !x(e),
  C = e => {
    if (P(e)) {
      for (var t in e) if (k.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  F = e => void 0 === e,
  R = e => "[object String]" == E.call(e),
  T = e => R(e) && 0 === e.trim().length,
  $ = e => null === e,
  A = e => F(e) || $(e),
  O = e => "[object Number]" == E.call(e),
  M = e => "[object Boolean]" === E.call(e),
  L = e => e instanceof FormData,
  D = e => e instanceof Error,
  q = e => m(v, e),
  N = e => {
    var i = {
      _log: function (i) {
        if (t && (p.DEBUG || _.POSTHOG_DEBUG) && !F(t.console) && t.console) {
          for (var s = ("__rrweb_original__" in t.console[i]) ? t.console[i].__rrweb_original__ : t.console[i], r = arguments.length, n = new Array(r > 1 ? r - 1 : 0), o = 1; o < r; o++) n[o - 1] = arguments[o];
          s(e, ...n);
        }
      },
      info: function () {
        for (var e = arguments.length, t = new Array(e), s = 0; s < e; s++) t[s] = arguments[s];
        i._log("log", ...t);
      },
      warn: function () {
        for (var e = arguments.length, t = new Array(e), s = 0; s < e; s++) t[s] = arguments[s];
        i._log("warn", ...t);
      },
      error: function () {
        for (var e = arguments.length, t = new Array(e), s = 0; s < e; s++) t[s] = arguments[s];
        i._log("error", ...t);
      },
      critical: function () {
        for (var t = arguments.length, i = new Array(t), s = 0; s < t; s++) i[s] = arguments[s];
        console.error(e, ...i);
      },
      uninitializedWarning: e => {
        i.error("You must initialize PostHog before calling ".concat(e));
      },
      createLogger: t => N("".concat(e, " ").concat(t))
    };
    return i;
  },
  B = N("[PostHog.js]"),
  H = B.createLogger,
  U = H("[ExternalScriptsLoader]"),
  z = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return U.warn("".concat(t, " was requested but loading of external scripts is disabled.")), i("Loading of external scripts is disabled");
    var s = () => {
      if (!a) return i("document not found");
      var s = a.createElement("script");
      if (s.type = "text/javascript", s.crossOrigin = "anonymous", s.src = t, s.onload = e => i(void 0, e), s.onerror = e => i(e), e.config.prepare_external_dependency_script && (s = e.config.prepare_external_dependency_script(s)), !s) return i("prepare_external_dependency_script returned null");
      var r,
        n = a.querySelectorAll("body > script");
      n.length > 0 ? null === (r = n[0].parentNode) || void 0 === r || r.insertBefore(s, n[0]) : a.body.appendChild(s);
    };
    null != a && a.body ? s() : null == a || a.addEventListener("DOMContentLoaded", s);
  };
function j(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    t && (s = s.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), i.push.apply(i, s);
  }
  return i;
}
function W(e) {
  for (var t = 1; t < arguments.length; t++) {
    var i = null != arguments[t] ? arguments[t] : {};
    t % 2 ? j(Object(i), !0).forEach(function (t) {
      V(e, t, i[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(i)) : j(Object(i)).forEach(function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(i, t));
    });
  }
  return e;
}
function V(e, t, i) {
  return t in e ? Object.defineProperty(e, t, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = i, e;
}
function G(e, t) {
  if (null == e) return {};
  var i,
    s,
    r = function (e, t) {
      if (null == e) return {};
      var i,
        s,
        r = {},
        n = Object.keys(e);
      for (s = 0; s < n.length; s++) i = n[s], t.indexOf(i) >= 0 || (r[i] = e[i]);
      return r;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (s = 0; s < n.length; s++) i = n[s], t.indexOf(i) >= 0 || Object.prototype.propertyIsEnumerable.call(e, i) && (r[i] = e[i]);
  }
  return r;
}
_.__PosthogExtensions__ = _.__PosthogExtensions__ || {}, _.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  var s = "/static/".concat(t, ".js") + "?v=".concat(e.version);
  if ("remote-config" === t && (s = "/array/".concat(e.config.token, "/config.js")), "toolbar" === t) {
    var r = 3e5,
      n = Math.floor(Date.now() / r) * r;
    s = "".concat(s, "&t=").concat(n);
  }
  var o = e.requestRouter.endpointFor("assets", s);
  z(e, o, i);
}, _.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  var s = e.requestRouter.endpointFor("api", t);
  z(e, s, i);
};
var J = {};
function Y(e, t, i) {
  if (x(e)) if (r && e.forEach === r) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (var s = 0, n = e.length; s < n; s++) if (s in e && t.call(i, e[s], s) === J) return;
}
function K(e, t, i) {
  if (!A(e)) {
    if (x(e)) return Y(e, t, i);
    if (L(e)) {
      for (var s of e.entries()) if (t.call(i, s[1], s[0]) === J) return;
    } else for (var r in e) if (k.call(e, r) && t.call(i, e[r], r) === J) return;
  }
}
var X = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), s = 1; s < t; s++) i[s - 1] = arguments[s];
    return Y(i, function (t) {
      for (var i in t) void 0 !== t[i] && (e[i] = t[i]);
    }), e;
  },
  Q = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), s = 1; s < t; s++) i[s - 1] = arguments[s];
    return Y(i, function (t) {
      Y(t, function (t) {
        e.push(t);
      });
    }), e;
  };
function Z(e) {
  for (var t = Object.keys(e), i = t.length, s = new Array(i); i--;) s[i] = [t[i], e[t[i]]];
  return s;
}
var ee = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  te = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), s = 0; s < t; s++) i[s] = arguments[s];
        return e.apply(this, i);
      } catch (e) {
        B.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), B.critical(e);
      }
    };
  },
  ie = function (e) {
    var t = {};
    return K(e, function (e, i) {
      R(e) && e.length > 0 && (t[i] = e);
    }), t;
  };
function se(e, t) {
  return i = e, s = e => R(e) && !$(t) ? e.slice(0, t) : e, r = new Set(), function e(t, i) {
    return t !== Object(t) ? s ? s(t, i) : t : r.has(t) ? void 0 : (r.add(t), x(t) ? (n = [], Y(t, t => {
      n.push(e(t));
    })) : (n = {}, K(t, (t, i) => {
      r.has(t) || (n[i] = e(t, i));
    })), n);
    var n;
  }(i);
  var i, s, r;
}
var re = ["herokuapp.com", "vercel.app", "netlify.app"];
function ne(e) {
  var t = null == e ? void 0 : e.hostname;
  if (!R(t)) return !1;
  var i = t.split(".").slice(-2).join(".");
  for (var s of re) if (i === s) return !1;
  return !0;
}
function oe(e, t) {
  for (var i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
function ae(e, t, i, s) {
  var {
    capture: r = !1,
    passive: n = !0
  } = null != s ? s : {};
  null == e || e.addEventListener(t, i, {
    capture: r,
    passive: n
  });
}
var le = "$people_distinct_id",
  ce = "__alias",
  ue = "__timers",
  de = "$autocapture_disabled_server_side",
  he = "$heatmaps_enabled_server_side",
  _e = "$exception_capture_enabled_server_side",
  pe = "$web_vitals_enabled_server_side",
  ge = "$dead_clicks_enabled_server_side",
  ve = "$web_vitals_allowed_metrics",
  fe = "$session_recording_enabled_server_side",
  me = "$console_log_recording_enabled_server_side",
  ye = "$session_recording_network_payload_capture",
  be = "$session_recording_masking",
  we = "$session_recording_canvas_recording",
  Se = "$replay_sample_rate",
  ke = "$replay_minimum_duration",
  Ee = "$replay_script_config",
  xe = "$sesid",
  Ie = "$session_is_sampled",
  Pe = "$session_recording_url_trigger_activated_session",
  Ce = "$session_recording_event_trigger_activated_session",
  Fe = "$enabled_feature_flags",
  Re = "$early_access_features",
  Te = "$stored_person_properties",
  $e = "$stored_group_properties",
  Ae = "$surveys",
  Oe = "$surveys_activated",
  Me = "$flag_call_reported",
  Le = "$user_state",
  De = "$client_session_props",
  qe = "$capture_rate_limit",
  Ne = "$initial_campaign_params",
  Be = "$initial_referrer_info",
  He = "$initial_person_info",
  Ue = "$epp",
  ze = "__POSTHOG_TOOLBAR__",
  je = "$posthog_cookieless",
  We = [le, ce, "__cmpns", ue, fe, he, xe, Fe, Le, Re, $e, Te, Ae, Me, De, qe, Ne, Be, Ue];
function Ve(e) {
  var t;
  return e instanceof Element && (e.id === ze || !(null === (t = e.closest) || void 0 === t || !t.call(e, ".toolbar-global-fade-container")));
}
function Ge(e) {
  return !!e && 1 === e.nodeType;
}
function Je(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function Ye(e) {
  return !!e && 3 === e.nodeType;
}
function Ke(e) {
  return !!e && 11 === e.nodeType;
}
function Xe(e) {
  return e ? y(e).split(/\s+/) : [];
}
function Qe(e) {
  var i = null == t ? void 0 : t.location.href;
  return !!(i && e && e.some(e => i.match(e)));
}
function Ze(e) {
  var t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return Xe(t);
}
function et(e) {
  return A(e) ? null : y(e).split(/(\s+)/).filter(e => pt(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function tt(e) {
  var t = "";
  return ot(e) && !at(e) && e.childNodes && e.childNodes.length && K(e.childNodes, function (e) {
    var i;
    Ye(e) && e.textContent && (t += null !== (i = et(e.textContent)) && void 0 !== i ? i : "");
  }), y(t);
}
function it(e) {
  return F(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
var st = ["a", "button", "form", "input", "select", "textarea", "label"];
function rt(e) {
  var t = e.parentNode;
  return !(!t || !Ge(t)) && t;
}
function nt(e, i) {
  var s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    r = arguments.length > 3 ? arguments[3] : void 0,
    n = arguments.length > 4 ? arguments[4] : void 0;
  if (!t || !e || Je(e, "html") || !Ge(e)) return !1;
  if (null != s && s.url_allowlist && !Qe(s.url_allowlist)) return !1;
  if (null != s && s.url_ignorelist && Qe(s.url_ignorelist)) return !1;
  if (null != s && s.dom_event_allowlist) {
    var o = s.dom_event_allowlist;
    if (o && !o.some(e => i.type === e)) return !1;
  }
  for (var a = !1, l = [e], c = !0, u = e; u.parentNode && !Je(u, "body");) if (Ke(u.parentNode)) l.push(u.parentNode.host), u = u.parentNode.host;else {
    if (!(c = rt(u))) break;
    if (r || st.indexOf(c.tagName.toLowerCase()) > -1) a = !0;else {
      var d = t.getComputedStyle(c);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    l.push(c), u = c;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (F(i)) return !0;
    var s = function (e) {
      if (i.some(t => e.tagName.toLowerCase() === t)) return {
        v: !0
      };
    };
    for (var r of e) {
      var n = s(r);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, s)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (F(i)) return !0;
    var s = function (e) {
      if (i.some(t => e.matches(t))) return {
        v: !0
      };
    };
    for (var r of e) {
      var n = s(r);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, s)) return !1;
  var h = t.getComputedStyle(e);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === i.type) return !0;
  var _ = e.tagName.toLowerCase();
  switch (_) {
    case "html":
      return !1;
    case "form":
      return (n || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (n || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return a ? (n || ["click"]).indexOf(i.type) >= 0 : (n || ["click"]).indexOf(i.type) >= 0 && (st.indexOf(_) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function ot(e) {
  for (var t = e; t.parentNode && !Je(t, "body"); t = t.parentNode) {
    var i = Ze(t);
    if (m(i, "ph-sensitive") || m(i, "ph-no-capture")) return !1;
  }
  if (m(Ze(e), "ph-include")) return !0;
  var s = e.type || "";
  if (R(s)) switch (s.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var r = e.name || e.id || "";
  if (R(r)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(r.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function at(e) {
  return !!(Je(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || Je(e, "select") || Je(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var lt = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  ct = new RegExp("^(?:".concat(lt, ")$")),
  ut = new RegExp(lt),
  dt = "\\d{3}-?\\d{2}-?\\d{4}",
  ht = new RegExp("^(".concat(dt, ")$")),
  _t = new RegExp("(".concat(dt, ")"));
function pt(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (A(e)) return !1;
  if (R(e)) {
    if (e = y(e), (t ? ct : ut).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? ht : _t).test(e)) return !1;
  }
  return !0;
}
function gt(e) {
  var t = tt(e);
  return pt(t = "".concat(t, " ").concat(vt(e)).trim()) ? t : "";
}
function vt(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && K(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      var s = tt(e);
      t = "".concat(t, " ").concat(s).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(vt(e)).trim());
    } catch (e) {
      B.error("[AutoCapture]", e);
    }
  }), t;
}
function ft(e) {
  return function (e) {
    var t = e.map(e => {
      var t,
        i,
        s = "";
      if (e.tag_name && (s += e.tag_name), e.attr_class) for (var r of (e.attr_class.sort(), e.attr_class)) s += ".".concat(r.replace(/"/g, ""));
      var n = W(W(W(W({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        o = {};
      return Z(n).sort((e, t) => {
        var [i] = e,
          [s] = t;
        return i.localeCompare(s);
      }).forEach(e => {
        var [t, i] = e;
        return o[mt(t.toString())] = mt(i.toString());
      }), s += ":", s += Z(n).map(e => {
        var [t, i] = e;
        return "".concat(t, '="').concat(i, '"');
      }).join("");
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t,
        i,
        s = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
          attr_class: yt(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return Z(e).filter(e => {
        var [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        var [t, i] = e;
        return s.attributes[t] = i;
      }), s;
    });
  }(e));
}
function mt(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function yt(e) {
  var t = e.attr__class;
  return t ? x(t) ? t : Xe(t) : void 0;
}
class bt {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    var s = this.clicks[this.clicks.length - 1];
    if (s && Math.abs(e - s.x) + Math.abs(t - s.y) < 30 && i - s.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
var wt = ["localhost", "127.0.0.1"],
  St = e => {
    var t = null == a ? void 0 : a.createElement("a");
    return F(t) ? null : (t.href = e, t);
  },
  kt = function (e) {
    var t,
      i,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      r = [];
    return K(e, function (e, s) {
      F(e) || F(s) || "undefined" === s || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(s), r[r.length] = i + "=" + t);
    }), r.join(s);
  },
  Et = function (e, t) {
    for (var i, s = ((e.split("#")[0] || "").split(/\?(.*)/)[1] || "").replace(/^\?+/g, "").split("&"), r = 0; r < s.length; r++) {
      var n = s[r].split("=");
      if (n[0] === t) {
        i = n;
        break;
      }
    }
    if (!x(i) || i.length < 2) return "";
    var o = i[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      B.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  xt = function (e, t, i) {
    if (!e || !t || !t.length) return e;
    for (var s = e.split("#"), r = s[0] || "", n = s[1], o = r.split("?"), a = o[1], l = o[0], c = (a || "").split("&"), u = [], d = 0; d < c.length; d++) {
      var h = c[d].split("=");
      x(h) && (t.includes(h[0]) ? u.push(h[0] + "=" + i) : u.push(c[d]));
    }
    var _ = l;
    return null != a && (_ += "?" + u.join("&")), null != n && (_ += "#" + n), _;
  },
  It = function (e, t) {
    var i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  Pt = H("[AutoCapture]");
function Ct(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
function Ft(e) {
  if (e.previousElementSibling) return e.previousElementSibling;
  var t = e;
  do {
    t = t.previousSibling;
  } while (t && !Ge(t));
  return t;
}
function Rt(e, t, i, s) {
  var r = e.tagName.toLowerCase(),
    n = {
      tag_name: r
    };
  st.indexOf(r) > -1 && !i && ("a" === r.toLowerCase() || "button" === r.toLowerCase() ? n.$el_text = Ct(1024, gt(e)) : n.$el_text = Ct(1024, tt(e)));
  var o = Ze(e);
  o.length > 0 && (n.classes = o.filter(function (e) {
    return "" !== e;
  })), K(e.attributes, function (i) {
    var r;
    if ((!at(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == s || !s.includes(i.name)) && !t && pt(i.value) && (r = i.name, !R(r) || "_ngcontent" !== r.substring(0, 10) && "_nghost" !== r.substring(0, 7))) {
      var o = i.value;
      "class" === i.name && (o = Xe(o).join(" ")), n["attr__" + i.name] = Ct(1024, o);
    }
  });
  for (var a = 1, l = 1, c = e; c = Ft(c);) a++, c.tagName === e.tagName && l++;
  return n.nth_child = a, n.nth_of_type = l, n;
}
function Tt(e, i) {
  for (var s, r, {
      e: n,
      maskAllElementAttributes: o,
      maskAllText: a,
      elementAttributeIgnoreList: l,
      elementsChainAsString: c
    } = i, u = [e], d = e; d.parentNode && !Je(d, "body");) Ke(d.parentNode) ? (u.push(d.parentNode.host), d = d.parentNode.host) : (u.push(d.parentNode), d = d.parentNode);
  var h,
    _ = [],
    p = {},
    g = !1,
    v = !1;
  if (K(u, e => {
    var t = ot(e);
    "a" === e.tagName.toLowerCase() && (g = e.getAttribute("href"), g = t && g && pt(g) && g), m(Ze(e), "ph-no-capture") && (v = !0), _.push(Rt(e, o, a, l));
    var i = function (e) {
      if (!ot(e)) return {};
      var t = {};
      return K(e.attributes, function (e) {
        if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
          var i = e.name.replace("data-ph-capture-attribute-", ""),
            s = e.value;
          i && s && pt(s) && (t[i] = s);
        }
      }), t;
    }(e);
    X(p, i);
  }), v) return {
    props: {},
    explicitNoCapture: v
  };
  if (a || ("a" === e.tagName.toLowerCase() || "button" === e.tagName.toLowerCase() ? _[0].$el_text = gt(e) : _[0].$el_text = tt(e)), g) {
    var f, y;
    _[0].attr__href = g;
    var b = null === (f = St(g)) || void 0 === f ? void 0 : f.host,
      w = null == t || null === (y = t.location) || void 0 === y ? void 0 : y.host;
    b && w && b !== w && (h = g);
  }
  return {
    props: X({
      $event_type: n.type,
      $ce_version: 1
    }, c ? {} : {
      $elements: _
    }, {
      $elements_chain: ft(_)
    }, null !== (s = _[0]) && void 0 !== s && s.$el_text ? {
      $el_text: null === (r = _[0]) || void 0 === r ? void 0 : r.$el_text
    } : {}, h && "click" === n.type ? {
      $external_click_url: h
    } : {}, p)
  };
}
class $t {
  constructor(e) {
    V(this, "_initialized", !1), V(this, "_isDisabledServerSide", null), V(this, "rageclicks", new bt()), V(this, "_elementsChainAsString", !1), this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e,
      t,
      i = P(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (this.isBrowserSupported()) {
      if (t && a) {
        var e = e => {
          e = e || (null == t ? void 0 : t.event);
          try {
            this._captureEvent(e);
          } catch (e) {
            Pt.error("Failed to capture event", e);
          }
        };
        if (ae(a, "submit", e, {
          capture: !0
        }), ae(a, "change", e, {
          capture: !0
        }), ae(a, "click", e, {
          capture: !0
        }), this.config.capture_copied_text) {
          var i = e => {
            e = e || (null == t ? void 0 : t.event), this._captureEvent(e, g);
          };
          ae(a, "copy", i, {
            capture: !0
          }), ae(a, "cut", i, {
            capture: !0
          });
        }
      }
    } else Pt.info("Disabling Automatic Event Collection because this browser is not supported");
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  onRemoteConfig(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [de]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t,
      i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      var s = null == a ? void 0 : a.querySelectorAll(t);
      null == s || s.forEach(s => {
        e === s && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e,
      t,
      i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[de],
      s = this._isDisabledServerSide;
    if ($(s) && !M(i) && !this.instance.config.advanced_disable_decide) return !1;
    var r = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !r;
  }
  _captureEvent(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (this.isEnabled) {
      var s,
        r = it(e);
      if (Ye(r) && (r = r.parentNode || null), "$autocapture" === i && "click" === e.type && e instanceof MouseEvent) this.instance.config.rageclick && null !== (s = this.rageclicks) && void 0 !== s && s.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, "$rageclick");
      var n = i === g;
      if (r && nt(r, e, this.config, n, n ? ["copy", "cut"] : void 0)) {
        var {
          props: o,
          explicitNoCapture: a
        } = Tt(r, {
          e: e,
          maskAllElementAttributes: this.instance.config.mask_all_element_attributes,
          maskAllText: this.instance.config.mask_all_text,
          elementAttributeIgnoreList: this.config.element_attribute_ignorelist,
          elementsChainAsString: this._elementsChainAsString
        });
        if (a) return !1;
        var l = this.getElementSelectors(r);
        if (l && l.length > 0 && (o.$element_selectors = l), i === g) {
          var c,
            u = et(null == t || null === (c = t.getSelection()) || void 0 === c ? void 0 : c.toString()),
            d = e.type || "clipboard";
          if (!u) return !1;
          o.$selected_content = u, o.$copy_type = d;
        }
        return this.instance.capture(i, o), !0;
      }
    }
  }
  isBrowserSupported() {
    return I(null == a ? void 0 : a.querySelectorAll);
  }
}
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return O(e) && isFinite(e) && Math.floor(e) === e;
});
var At = "0123456789abcdef";
class Ot {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, s) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(s) || e < 0 || t < 0 || i < 0 || s < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || s > 4294967295) throw new RangeError("invalid field value");
    var r = new Uint8Array(16);
    return r[0] = e / Math.pow(2, 40), r[1] = e / Math.pow(2, 32), r[2] = e / Math.pow(2, 24), r[3] = e / Math.pow(2, 16), r[4] = e / Math.pow(2, 8), r[5] = e, r[6] = 112 | t >>> 8, r[7] = t, r[8] = 128 | i >>> 24, r[9] = i >>> 16, r[10] = i >>> 8, r[11] = i, r[12] = s >>> 24, r[13] = s >>> 16, r[14] = s >>> 8, r[15] = s, new Ot(r);
  }
  toString() {
    for (var e = "", t = 0; t < this.bytes.length; t++) e = e + At.charAt(this.bytes[t] >>> 4) + At.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new Ot(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (var t = 0; t < 16; t++) {
      var i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class Mt {
  constructor() {
    V(this, "timestamp", 0), V(this, "counter", 0), V(this, "random", new qt());
  }
  generate() {
    var e = this.generateOrAbort();
    if (F(e)) {
      this.timestamp = 0;
      var t = this.generateOrAbort();
      if (F(t)) throw new Error("Could not generate UUID after timestamp reset");
      return t;
    }
    return e;
  }
  generateOrAbort() {
    var e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return Ot.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
var Lt,
  Dt = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
t && !F(t.crypto) && crypto.getRandomValues && (Dt = e => crypto.getRandomValues(e));
class qt {
  constructor() {
    V(this, "buffer", new Uint32Array(8)), V(this, "cursor", 1 / 0);
  }
  nextUint32() {
    return this.cursor >= this.buffer.length && (Dt(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
var Nt = () => Bt().toString(),
  Bt = () => (Lt || (Lt = new Mt())).generate(),
  Ht = "Thu, 01 Jan 1970 00:00:00 GMT",
  Ut = "";
var zt = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function jt(e, t) {
  if (t) {
    var i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : a;
      if (Ut) return Ut;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var i = e.split("."), s = Math.min(i.length, 8), r = "dmn_chk_" + Nt(), n = new RegExp("(^|;)\\s*" + r + "=1"); !Ut && s--;) {
        var o = i.slice(s).join("."),
          l = r + "=1;domain=." + o;
        t.cookie = l, n.test(t.cookie) && (t.cookie = l + ";expires=" + Ht, Ut = o);
      }
      return Ut;
    }(e);
    if (!i) {
      var s = (e => {
        var t = e.match(zt);
        return t ? t[0] : "";
      })(e);
      s !== i && B.info("Warning: cookie subdomain discovery mismatch", s, i), i = s;
    }
    return i ? "; domain=." + i : "";
  }
  return "";
}
var Wt,
  Vt = {
    is_supported: () => !!a,
    error: function (e) {
      B.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (a) {
        try {
          for (var t = e + "=", i = a.cookie.split(";").filter(e => e.length), s = 0; s < i.length; s++) {
            for (var r = i[s]; " " == r.charAt(0);) r = r.substring(1, r.length);
            if (0 === r.indexOf(t)) return decodeURIComponent(r.substring(t.length, r.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(Vt.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, i, s, r) {
      if (a) try {
        var n = "",
          o = "",
          l = jt(a.location.hostname, s);
        if (i) {
          var c = new Date();
          c.setTime(c.getTime() + 24 * i * 60 * 60 * 1e3), n = "; expires=" + c.toUTCString();
        }
        r && (o = "; secure");
        var u = e + "=" + encodeURIComponent(JSON.stringify(t)) + n + "; SameSite=Lax; path=/" + l + o;
        return u.length > 3686.4 && B.warn("cookieStore warning: large cookie, len=" + u.length), a.cookie = u, u;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        Vt.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  Gt = null,
  Jt = {
    is_supported: function () {
      if (!$(Gt)) return Gt;
      var e = !0;
      if (F(t)) e = !1;else try {
        var i = "__mplssupport__";
        Jt.set(i, "xyz"), '"xyz"' !== Jt.get(i) && (e = !1), Jt.remove(i);
      } catch (t) {
        e = !1;
      }
      return e || B.error("localStorage unsupported; falling back to cookie store"), Gt = e, e;
    },
    error: function (e) {
      B.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.localStorage.getItem(e);
      } catch (e) {
        Jt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(Jt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.localStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        Jt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.localStorage.removeItem(e);
      } catch (e) {
        Jt.error(e);
      }
    }
  },
  Yt = ["distinct_id", xe, Ie, Ue, He],
  Kt = W(W({}, Jt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = Vt.parse(e) || {};
        } catch (e) {}
        var i = X(t, JSON.parse(Jt.get(e) || "{}"));
        return Jt.set(e, i), i;
      } catch (e) {}
      return null;
    },
    set: function (e, t, i, s, r, n) {
      try {
        Jt.set(e, t, void 0, void 0, n);
        var o = {};
        Yt.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && Vt.set(e, o, i, s, r, n);
      } catch (e) {
        Jt.error(e);
      }
    },
    remove: function (e, i) {
      try {
        null == t || t.localStorage.removeItem(e), Vt.remove(e, i);
      } catch (e) {
        Jt.error(e);
      }
    }
  }),
  Xt = {},
  Qt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      B.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return Xt[e] || null;
    },
    parse: function (e) {
      return Xt[e] || null;
    },
    set: function (e, t) {
      Xt[e] = t;
    },
    remove: function (e) {
      delete Xt[e];
    }
  },
  Zt = null,
  ei = {
    is_supported: function () {
      if (!$(Zt)) return Zt;
      if (Zt = !0, F(t)) Zt = !1;else try {
        var e = "__support__";
        ei.set(e, "xyz"), '"xyz"' !== ei.get(e) && (Zt = !1), ei.remove(e);
      } catch (e) {
        Zt = !1;
      }
      return Zt;
    },
    error: function (e) {
      B.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.sessionStorage.getItem(e);
      } catch (e) {
        ei.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(ei.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.sessionStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        ei.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.sessionStorage.removeItem(e);
      } catch (e) {
        ei.error(e);
      }
    }
  };
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(Wt || (Wt = {}));
class ti {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? Wt.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === Wt.DENIED || this.consent === Wt.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    var {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    var e = this.storage.get(this.storageKey);
    return "1" === e ? Wt.GRANTED : "0" === e ? Wt.DENIED : Wt.PENDING;
  }
  get storage() {
    if (!this._storage) {
      var e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? Jt : Vt;
      var t = "localStorage" === e ? Vt : Jt;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!oe([null == o ? void 0 : o.doNotTrack, null == o ? void 0 : o.msDoNotTrack, _.doNotTrack], e => m([!0, 1, "1", "yes"], e));
  }
}
var ii = H("[Dead Clicks]"),
  si = () => !0,
  ri = e => {
    var t,
      i = !(null === (t = e.instance.persistence) || void 0 === t || !t.get_property(ge)),
      s = e.instance.config.capture_dead_clicks;
    return M(s) ? s : i;
  };
class ni {
  get lazyLoadedDeadClicksAutocapture() {
    return this._lazyLoadedDeadClicksAutocapture;
  }
  constructor(e, t, i) {
    this.instance = e, this.isEnabled = t, this.onCapture = i, this.startIfEnabled();
  }
  onRemoteConfig(e) {
    this.instance.persistence && this.instance.persistence.register({
      [ge]: null == e ? void 0 : e.captureDeadClicks
    }), this.startIfEnabled();
  }
  startIfEnabled() {
    this.isEnabled(this) && this.loadScript(() => {
      this.start();
    });
  }
  loadScript(e) {
    var t, i, s;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.initDeadClicksAutocapture && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "dead-clicks-autocapture", t => {
      t ? ii.error("failed to load script", t) : e();
    });
  }
  start() {
    var e;
    if (a) {
      if (!this._lazyLoadedDeadClicksAutocapture && null !== (e = _.__PosthogExtensions__) && void 0 !== e && e.initDeadClicksAutocapture) {
        var t = P(this.instance.config.capture_dead_clicks) ? this.instance.config.capture_dead_clicks : {};
        t.__onCapture = this.onCapture, this._lazyLoadedDeadClicksAutocapture = _.__PosthogExtensions__.initDeadClicksAutocapture(this.instance, t), this._lazyLoadedDeadClicksAutocapture.start(a), ii.info("starting...");
      }
    } else ii.error("`document` not found. Cannot start.");
  }
  stop() {
    this._lazyLoadedDeadClicksAutocapture && (this._lazyLoadedDeadClicksAutocapture.stop(), this._lazyLoadedDeadClicksAutocapture = void 0, ii.info("stopping..."));
  }
}
var oi = H("[ExceptionAutocapture]");
class ai {
  constructor(e) {
    var i;
    V(this, "startCapturing", () => {
      var e, i, s, r;
      if (t && this.isEnabled && !this.hasHandlers) {
        var n = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (i = e.errorWrappingFunctions) || void 0 === i ? void 0 : i.wrapOnError,
          o = null === (s = _.__PosthogExtensions__) || void 0 === s || null === (r = s.errorWrappingFunctions) || void 0 === r ? void 0 : r.wrapUnhandledRejection;
        if (n && o) try {
          this.unwrapOnError = n(this.captureException.bind(this)), this.unwrapUnhandledRejection = o(this.captureException.bind(this));
        } catch (e) {
          oi.error("failed to start", e), this.stopCapturing();
        } else oi.error("failed to load error wrapping functions - cannot start");
      }
    }), this.instance = e, this.remoteEnabled = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[_e]), this.startIfEnabled();
  }
  get isEnabled() {
    var e;
    return M(this.instance.config.capture_exceptions) ? this.instance.config.capture_exceptions : null !== (e = this.remoteEnabled) && void 0 !== e && e;
  }
  get hasHandlers() {
    return !F(this.unwrapOnError);
  }
  startIfEnabled() {
    this.isEnabled && !this.hasHandlers && (oi.info("enabled, starting..."), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i;
    this.hasHandlers && e(), null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "exception-autocapture", t => {
      if (t) return oi.error("failed to load script", t);
      e();
    });
  }
  stopCapturing() {
    var e, t;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), this.unwrapOnError = void 0, null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this), this.unwrapUnhandledRejection = void 0;
  }
  onRemoteConfig(e) {
    var t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.instance.persistence && this.instance.persistence.register({
      [_e]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    var t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = "".concat(t, "/project/").concat(this.instance.config.token, "/person/").concat(this.instance.get_distinct_id()), this.instance.exceptions.sendExceptionEvent(e);
  }
}
function li(e) {
  var t, i;
  return (null === (t = JSON.stringify(e, (i = [], function (e, t) {
    if (P(t)) {
      for (; i.length > 0 && i[i.length - 1] !== this;) i.pop();
      return i.includes(t) ? "[Circular]" : (i.push(t), t);
    }
    return t;
  }))) || void 0 === t ? void 0 : t.length) || 0;
}
function ci(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    var i = Math.floor(e.data.length / 2),
      s = e.data.slice(0, i),
      r = e.data.slice(i);
    return [ci({
      size: li(s),
      data: s,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), ci({
      size: li(r),
      data: r,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var ui = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(ui || {}),
  di = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(di || {}),
  hi = "[SessionRecording]",
  _i = "redacted",
  pi = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io", ".clarity.ms", "analytics.google.com"]
  },
  gi = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  vi = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  fi = ["/s/", "/e/", "/i/"];
function mi(e, t, i, s) {
  if (A(e)) return e;
  var r = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return R(r) && (r = parseInt(r)), r > i ? hi + " ".concat(s, " body too large to record (").concat(r, " bytes)") : e;
}
function yi(e, t) {
  if (A(e)) return e;
  var i = e;
  return pt(i, !1) || (i = hi + " " + t + " body " + _i), K(vi, e => {
    var s, r;
    null !== (s = i) && void 0 !== s && s.length && -1 !== (null === (r = i) || void 0 === r ? void 0 : r.indexOf(e)) && (i = hi + " " + t + " body " + _i + " as might contain: " + e);
  }), i;
}
var bi = (e, t) => {
  var i,
    s,
    r,
    n = {
      payloadSizeLimitBytes: pi.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...pi.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...pi.payloadHostDenyList]
    },
    o = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    a = !1 !== e.session_recording.recordBody && t.recordBody,
    l = !1 !== e.capture_performance && t.recordPerformance,
    c = (i = n, r = Math.min(1e6, null !== (s = i.payloadSizeLimitBytes) && void 0 !== s ? s : 1e6), e => (null != e && e.requestBody && (e.requestBody = mi(e.requestBody, e.requestHeaders, r, "Request")), null != e && e.responseBody && (e.responseBody = mi(e.responseBody, e.responseHeaders, r, "Response")), e)),
    u = t => {
      return c(((e, t) => {
        var i,
          s = St(e.name),
          r = 0 === t.indexOf("http") ? null === (i = St(t)) || void 0 === i ? void 0 : i.pathname : t;
        "/" === r && (r = "");
        var n = null == s ? void 0 : s.pathname.replace(r || "", "");
        if (!(s && n && fi.some(e => 0 === n.indexOf(e)))) return e;
      })((s = (i = t).requestHeaders, A(s) || K(Object.keys(null != s ? s : {}), e => {
        gi.includes(e.toLowerCase()) && (s[e] = _i);
      }), i), e.api_host));
      var i, s;
    },
    d = I(e.session_recording.maskNetworkRequestFn);
  return d && I(e.session_recording.maskCapturedNetworkRequestFn) && B.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), d && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    var i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return W(W({}, t), {}, {
      name: null == i ? void 0 : i.url
    });
  }), n.maskRequestFn = I(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i,
      s,
      r,
      n = u(t);
    return n && null !== (i = null === (s = (r = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === s ? void 0 : s.call(r, n)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!F(e)) return e.requestBody = yi(e.requestBody, "Request"), e.responseBody = yi(e.responseBody, "Response"), e;
  }(u(e)), W(W(W({}, pi), n), {}, {
    recordHeaders: o,
    recordBody: a,
    recordPerformance: l,
    recordInitialRequests: l
  });
};
function wi(e, t, i, s, r) {
  return t > i && (B.warn("min cannot be greater than max."), t = i), O(e) ? e > i ? (s && B.warn(s + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (s && B.warn(s + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (s && B.warn(s + " must be a number. using max or fallback. max: " + i + ", fallback: " + r), wi(r || i, t, i, s));
}
class Si {
  constructor(e) {
    var t,
      i,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    V(this, "bucketSize", 100), V(this, "refillRate", 10), V(this, "mutationBuckets", {}), V(this, "loggedTracker", {}), V(this, "refillBuckets", () => {
      Object.keys(this.mutationBuckets).forEach(e => {
        this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
      });
    }), V(this, "getNodeOrRelevantParent", e => {
      var t = this.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var i = t.closest("svg");
        if (i) return [this.rrweb.mirror.getId(i), i];
      }
      return [e, t];
    }), V(this, "numberOfChanges", e => {
      var t, i, s, r, n, o, a, l;
      return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (s = null === (r = e.attributes) || void 0 === r ? void 0 : r.length) && void 0 !== s ? s : 0) + (null !== (n = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== n ? n : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
    }), V(this, "throttleMutations", e => {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        i = this.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(e => {
        var t,
          i,
          s,
          [r, n] = this.getNodeOrRelevantParent(e.id);
        if (0 === this.mutationBuckets[r]) return !1;
        (this.mutationBuckets[r] = null !== (t = this.mutationBuckets[r]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[r] = Math.max(this.mutationBuckets[r] - 1, 0), 0 === this.mutationBuckets[r]) && (this.loggedTracker[r] || (this.loggedTracker[r] = !0, null === (i = (s = this.options).onBlockedNode) || void 0 === i || i.call(s, r, n)));
        return e;
      }));
      var s = this.numberOfChanges(t);
      return 0 !== s || i === s ? e : void 0;
    }), this.rrweb = e, this.options = s, this.refillRate = wi(null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, 0, 100, "mutation throttling refill rate"), this.bucketSize = wi(null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, 0, 100, "mutation throttling bucket size"), setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
}
var ki = Uint8Array,
  Ei = Uint16Array,
  xi = Uint32Array,
  Ii = new ki([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  Pi = new ki([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  Ci = new ki([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  Fi = function (e, t) {
    for (var i = new Ei(31), s = 0; s < 31; ++s) i[s] = t += 1 << e[s - 1];
    var r = new xi(i[30]);
    for (s = 1; s < 30; ++s) for (var n = i[s]; n < i[s + 1]; ++n) r[n] = n - i[s] << 5 | s;
    return [i, r];
  },
  Ri = Fi(Ii, 2),
  Ti = Ri[0],
  $i = Ri[1];
Ti[28] = 258, $i[258] = 28;
for (var Ai = Fi(Pi, 0)[1], Oi = new Ei(32768), Mi = 0; Mi < 32768; ++Mi) {
  var Li = (43690 & Mi) >>> 1 | (21845 & Mi) << 1;
  Li = (61680 & (Li = (52428 & Li) >>> 2 | (13107 & Li) << 2)) >>> 4 | (3855 & Li) << 4, Oi[Mi] = ((65280 & Li) >>> 8 | (255 & Li) << 8) >>> 1;
}
var Di = function (e, t, i) {
    for (var s = e.length, r = 0, n = new Ei(t); r < s; ++r) ++n[e[r] - 1];
    var o,
      a = new Ei(t);
    for (r = 0; r < t; ++r) a[r] = a[r - 1] + n[r - 1] << 1;
    if (i) {
      o = new Ei(1 << t);
      var l = 15 - t;
      for (r = 0; r < s; ++r) if (e[r]) for (var c = r << 4 | e[r], u = t - e[r], d = a[e[r] - 1]++ << u, h = d | (1 << u) - 1; d <= h; ++d) o[Oi[d] >>> l] = c;
    } else for (o = new Ei(s), r = 0; r < s; ++r) o[r] = Oi[a[e[r] - 1]++] >>> 15 - e[r];
    return o;
  },
  qi = new ki(288);
for (Mi = 0; Mi < 144; ++Mi) qi[Mi] = 8;
for (Mi = 144; Mi < 256; ++Mi) qi[Mi] = 9;
for (Mi = 256; Mi < 280; ++Mi) qi[Mi] = 7;
for (Mi = 280; Mi < 288; ++Mi) qi[Mi] = 8;
var Ni = new ki(32);
for (Mi = 0; Mi < 32; ++Mi) Ni[Mi] = 5;
var Bi = Di(qi, 9, 0),
  Hi = Di(Ni, 5, 0),
  Ui = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  zi = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var s = new (e instanceof Ei ? Ei : e instanceof xi ? xi : ki)(i - t);
    return s.set(e.subarray(t, i)), s;
  },
  ji = function (e, t, i) {
    i <<= 7 & t;
    var s = t / 8 >> 0;
    e[s] |= i, e[s + 1] |= i >>> 8;
  },
  Wi = function (e, t, i) {
    i <<= 7 & t;
    var s = t / 8 >> 0;
    e[s] |= i, e[s + 1] |= i >>> 8, e[s + 2] |= i >>> 16;
  },
  Vi = function (e, t) {
    for (var i = [], s = 0; s < e.length; ++s) e[s] && i.push({
      s: s,
      f: e[s]
    });
    var r = i.length,
      n = i.slice();
    if (!r) return [new ki(0), 0];
    if (1 == r) {
      var o = new ki(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      c = 0,
      u = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; u != r - 1;) a = i[i[c].f < i[d].f ? c++ : d++], l = i[c != u && i[c].f < i[d].f ? c++ : d++], i[u++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = n[0].s;
    for (s = 1; s < r; ++s) n[s].s > h && (h = n[s].s);
    var _ = new Ei(h + 1),
      p = Gi(i[u - 1], _, 0);
    if (p > t) {
      s = 0;
      var g = 0,
        v = p - t,
        f = 1 << v;
      for (n.sort(function (e, t) {
        return _[t.s] - _[e.s] || e.f - t.f;
      }); s < r; ++s) {
        var m = n[s].s;
        if (!(_[m] > t)) break;
        g += f - (1 << p - _[m]), _[m] = t;
      }
      for (g >>>= v; g > 0;) {
        var y = n[s].s;
        _[y] < t ? g -= 1 << t - _[y]++ - 1 : ++s;
      }
      for (; s >= 0 && g; --s) {
        var b = n[s].s;
        _[b] == t && (--_[b], ++g);
      }
      p = t;
    }
    return [new ki(_), p];
  },
  Gi = function (e, t, i) {
    return -1 == e.s ? Math.max(Gi(e.l, t, i + 1), Gi(e.r, t, i + 1)) : t[e.s] = i;
  },
  Ji = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new Ei(++t), s = 0, r = e[0], n = 1, o = function (e) {
        i[s++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == r && a != t) ++n;else {
      if (!r && n > 2) {
        for (; n > 138; n -= 138) o(32754);
        n > 2 && (o(n > 10 ? n - 11 << 5 | 28690 : n - 3 << 5 | 12305), n = 0);
      } else if (n > 3) {
        for (o(r), --n; n > 6; n -= 6) o(8304);
        n > 2 && (o(n - 3 << 5 | 8208), n = 0);
      }
      for (; n--;) o(r);
      n = 1, r = e[a];
    }
    return [i.subarray(0, s), t];
  },
  Yi = function (e, t) {
    for (var i = 0, s = 0; s < t.length; ++s) i += e[s] * t[s];
    return i;
  },
  Ki = function (e, t, i) {
    var s = i.length,
      r = Ui(t + 2);
    e[r] = 255 & s, e[r + 1] = s >>> 8, e[r + 2] = 255 ^ e[r], e[r + 3] = 255 ^ e[r + 1];
    for (var n = 0; n < s; ++n) e[r + n + 4] = i[n];
    return 8 * (r + 4 + s);
  },
  Xi = function (e, t, i, s, r, n, o, a, l, c, u) {
    ji(t, u++, i), ++r[256];
    for (var d = Vi(r, 15), h = d[0], _ = d[1], p = Vi(n, 15), g = p[0], v = p[1], f = Ji(h), m = f[0], y = f[1], b = Ji(g), w = b[0], S = b[1], k = new Ei(19), E = 0; E < m.length; ++E) k[31 & m[E]]++;
    for (E = 0; E < w.length; ++E) k[31 & w[E]]++;
    for (var x = Vi(k, 7), I = x[0], P = x[1], C = 19; C > 4 && !I[Ci[C - 1]]; --C);
    var F,
      R,
      T,
      $,
      A = c + 5 << 3,
      O = Yi(r, qi) + Yi(n, Ni) + o,
      M = Yi(r, h) + Yi(n, g) + o + 14 + 3 * C + Yi(k, I) + (2 * k[16] + 3 * k[17] + 7 * k[18]);
    if (A <= O && A <= M) return Ki(t, u, e.subarray(l, l + c));
    if (ji(t, u, 1 + (M < O)), u += 2, M < O) {
      F = Di(h, _, 0), R = h, T = Di(g, v, 0), $ = g;
      var L = Di(I, P, 0);
      ji(t, u, y - 257), ji(t, u + 5, S - 1), ji(t, u + 10, C - 4), u += 14;
      for (E = 0; E < C; ++E) ji(t, u + 3 * E, I[Ci[E]]);
      u += 3 * C;
      for (var D = [m, w], q = 0; q < 2; ++q) {
        var N = D[q];
        for (E = 0; E < N.length; ++E) {
          var B = 31 & N[E];
          ji(t, u, L[B]), u += I[B], B > 15 && (ji(t, u, N[E] >>> 5 & 127), u += N[E] >>> 12);
        }
      }
    } else F = Bi, R = qi, T = Hi, $ = Ni;
    for (E = 0; E < a; ++E) if (s[E] > 255) {
      B = s[E] >>> 18 & 31;
      Wi(t, u, F[B + 257]), u += R[B + 257], B > 7 && (ji(t, u, s[E] >>> 23 & 31), u += Ii[B]);
      var H = 31 & s[E];
      Wi(t, u, T[H]), u += $[H], H > 3 && (Wi(t, u, s[E] >>> 5 & 8191), u += Pi[H]);
    } else Wi(t, u, F[s[E]]), u += R[s[E]];
    return Wi(t, u, F[256]), u + R[256];
  },
  Qi = new xi([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Zi = function () {
    for (var e = new xi(256), t = 0; t < 256; ++t) {
      for (var i = t, s = 9; --s;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  es = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, s = 0; s < t.length; ++s) i = Zi[255 & i ^ t[s]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  ts = function (e, t, i, s, r) {
    return function (e, t, i, s, r, n) {
      var o = e.length,
        a = new ki(s + o + 5 * (1 + Math.floor(o / 7e3)) + r),
        l = a.subarray(s, a.length - r),
        c = 0;
      if (!t || o < 8) for (var u = 0; u <= o; u += 65535) {
        var d = u + 65535;
        d < o ? c = Ki(l, c, e.subarray(u, d)) : (l[u] = n, c = Ki(l, c, e.subarray(u, o)));
      } else {
        for (var h = Qi[t - 1], _ = h >>> 13, p = 8191 & h, g = (1 << i) - 1, v = new Ei(32768), f = new Ei(g + 1), m = Math.ceil(i / 3), y = 2 * m, b = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << y) & g;
          }, w = new xi(25e3), S = new Ei(288), k = new Ei(32), E = 0, x = 0, I = (u = 0, 0), P = 0, C = 0; u < o; ++u) {
          var F = b(u),
            R = 32767 & u,
            T = f[F];
          if (v[R] = T, f[F] = R, P <= u) {
            var $ = o - u;
            if ((E > 7e3 || I > 24576) && $ > 423) {
              c = Xi(e, l, 0, w, S, k, x, I, C, u - C, c), I = E = x = 0, C = u;
              for (var A = 0; A < 286; ++A) S[A] = 0;
              for (A = 0; A < 30; ++A) k[A] = 0;
            }
            var O = 2,
              M = 0,
              L = p,
              D = R - T & 32767;
            if ($ > 2 && F == b(u - D)) for (var q = Math.min(_, $) - 1, N = Math.min(32767, u), B = Math.min(258, $); D <= N && --L && R != T;) {
              if (e[u + O] == e[u + O - D]) {
                for (var H = 0; H < B && e[u + H] == e[u + H - D]; ++H);
                if (H > O) {
                  if (O = H, M = D, H > q) break;
                  var U = Math.min(D, H - 2),
                    z = 0;
                  for (A = 0; A < U; ++A) {
                    var j = u - D + A + 32768 & 32767,
                      W = j - v[j] + 32768 & 32767;
                    W > z && (z = W, T = j);
                  }
                }
              }
              D += (R = T) - (T = v[R]) + 32768 & 32767;
            }
            if (M) {
              w[I++] = 268435456 | $i[O] << 18 | Ai[M];
              var V = 31 & $i[O],
                G = 31 & Ai[M];
              x += Ii[V] + Pi[G], ++S[257 + V], ++k[G], P = u + O, ++E;
            } else w[I++] = e[u], ++S[e[u]];
          }
        }
        c = Xi(e, l, n, w, S, k, x, I, C, u - C, c);
      }
      return zi(a, 0, s + Ui(c) + r);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, s, !r);
  },
  is = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  ss = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && is(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var s = 0; s <= i.length; ++s) e[s + 10] = i.charCodeAt(s);
    }
  },
  rs = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function ns(e, t) {
  void 0 === t && (t = {});
  var i = es(),
    s = e.length;
  i.p(e);
  var r = ts(e, t, rs(t), 8),
    n = r.length;
  return ss(r, t), is(r, n - 8, i.d()), is(r, n - 4, s), r;
}
function os(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var s = new ki(e.length + (e.length >>> 1)), r = 0, n = function (e) {
      s[r++] = e;
    }, o = 0; o < i; ++o) {
    if (r + 5 > s.length) {
      var a = new ki(r + 8 + (i - o << 1));
      a.set(s), s = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? n(l) : l < 2048 ? (n(192 | l >>> 6), n(128 | 63 & l)) : l > 55295 && l < 57344 ? (n(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), n(128 | l >>> 12 & 63), n(128 | l >>> 6 & 63), n(128 | 63 & l)) : (n(224 | l >>> 12), n(128 | l >>> 6 & 63), n(128 | 63 & l));
  }
  return zi(s, 0, r);
}
function as(e, t) {
  return function (e) {
    for (var t = 0, i = 0; i < e.length; i++) t = (t << 5) - t + e.charCodeAt(i), t |= 0;
    return Math.abs(t);
  }(e) % 100 < wi(100 * t, 0, 100);
}
var ls = "[SessionRecording]",
  cs = H(ls),
  us = 3e5,
  ds = [di.MouseMove, di.MouseInteraction, di.Scroll, di.ViewportResize, di.Input, di.TouchMove, di.MediaInteraction, di.Drag],
  hs = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  });
function _s(e) {
  return function (e, t) {
    for (var i = "", s = 0; s < e.length;) {
      var r = e[s++];
      r < 128 || t ? i += String.fromCharCode(r) : r < 224 ? i += String.fromCharCode((31 & r) << 6 | 63 & e[s++]) : r < 240 ? i += String.fromCharCode((15 & r) << 12 | (63 & e[s++]) << 6 | 63 & e[s++]) : (r = ((15 & r) << 18 | (63 & e[s++]) << 12 | (63 & e[s++]) << 6 | 63 & e[s++]) - 65536, i += String.fromCharCode(55296 | r >> 10, 56320 | 1023 & r));
    }
    return i;
  }(ns(os(JSON.stringify(e))), !0);
}
function ps(e) {
  return e.type === ui.Custom && "sessionIdle" === e.data.tag;
}
function gs(e, t) {
  return t.some(t => "regex" === t.matching && new RegExp(t.url).test(e));
}
class vs {
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get rrwebRecord() {
    var e, t;
    return null == _ || null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(ls + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.triggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : us;
  }
  get isSampled() {
    var e = this.instance.get_property(Ie);
    return M(e) ? e : null;
  }
  get sessionDuration() {
    var e,
      t,
      i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: s
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - s : null;
  }
  get isRecordingEnabled() {
    var e = !!this.instance.get_property(fe),
      i = !this.instance.config.disable_session_recording;
    return t && e && i;
  }
  get isConsoleLogCaptureEnabled() {
    var e = !!this.instance.get_property(me),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e,
      t,
      i,
      s,
      r,
      n,
      o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(we),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      c = null !== (i = null !== (s = null == o ? void 0 : o.canvasFps) && void 0 !== s ? s : null == a ? void 0 : a.fps) && void 0 !== i ? i : 4,
      u = null !== (r = null !== (n = null == o ? void 0 : o.canvasQuality) && void 0 !== n ? n : null == a ? void 0 : a.quality) && void 0 !== r ? r : .4;
    if ("string" == typeof u) {
      var d = parseFloat(u);
      u = isNaN(d) ? .4 : d;
    }
    return {
      enabled: l,
      fps: wi(c, 0, 12, "canvas recording fps", 4),
      quality: wi(u, 0, 1, "canvas recording quality", .4)
    };
  }
  get networkPayloadCapture() {
    var e,
      t,
      i = this.instance.get_property(ye),
      s = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      r = (null == s ? void 0 : s.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      n = (null == s ? void 0 : s.recordBody) || (null == i ? void 0 : i.recordBody),
      o = P(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(M(o) ? o : null == i ? void 0 : i.capturePerformance);
    return r || n || a ? {
      recordHeaders: r,
      recordBody: n,
      recordPerformance: a
    } : void 0;
  }
  get masking() {
    var e,
      t,
      i,
      s,
      r = this.instance.get_property(be),
      n = {
        maskAllInputs: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.maskAllInputs,
        maskTextSelector: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.maskTextSelector
      },
      o = null !== (i = null == n ? void 0 : n.maskAllInputs) && void 0 !== i ? i : null == r ? void 0 : r.maskAllInputs,
      a = null !== (s = null == n ? void 0 : n.maskTextSelector) && void 0 !== s ? s : null == r ? void 0 : r.maskTextSelector;
    return F(o) && F(a) ? void 0 : {
      maskAllInputs: o,
      maskTextSelector: a
    };
  }
  get sampleRate() {
    var e = this.instance.get_property(Se);
    return O(e) ? e : null;
  }
  get minimumDuration() {
    var e = this.instance.get_property(ke);
    return O(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? !1 === this.isSampled ? "disabled" : this._urlBlocked ? "paused" : A(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.triggerStatus ? "buffering" : M(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e;
    return 0 === this._urlTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Pe)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get eventTriggerStatus() {
    var e;
    return 0 === this._eventTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ce)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get triggerStatus() {
    var e = "trigger_activated" === this.eventTriggerStatus || "trigger_activated" === this.urlTriggerStatus,
      t = "trigger_pending" === this.eventTriggerStatus || "trigger_pending" === this.urlTriggerStatus;
    return e ? "trigger_activated" : t ? "trigger_pending" : "trigger_disabled";
  }
  constructor(e) {
    if (V(this, "queuedRRWebEvents", []), V(this, "isIdle", !1), V(this, "_linkedFlagSeen", !1), V(this, "_lastActivityTimestamp", Date.now()), V(this, "_linkedFlag", null), V(this, "_removePageViewCaptureHook", void 0), V(this, "_onSessionIdListener", void 0), V(this, "_persistDecideOnSessionListener", void 0), V(this, "_samplingSessionListener", void 0), V(this, "_urlTriggers", []), V(this, "_urlBlocklist", []), V(this, "_urlBlocked", !1), V(this, "_eventTriggers", []), V(this, "_removeEventTriggerCaptureHook", void 0), V(this, "_forceAllowLocalhostNetworkCapture", !1), V(this, "_onBeforeUnload", () => {
      this._flushBuffer();
    }), V(this, "_onOffline", () => {
      this._tryAddCustomEvent("browser offline", {});
    }), V(this, "_onOnline", () => {
      this._tryAddCustomEvent("browser online", {});
    }), V(this, "_onVisibilityChange", () => {
      if (null != a && a.visibilityState) {
        var e = "window " + a.visibilityState;
        this._tryAddCustomEvent(e, {});
      }
    }), this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw cs.error("started without valid sessionManager"), new Error(ls + " started without valid sessionManager. This is a bug.");
    if (this.instance.config.__preview_experimental_cookieless_mode) throw new Error(ls + " cannot be used with __preview_experimental_cookieless_mode.");
    var {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && cs.warn("session_idle_threshold_ms (".concat(this.sessionIdleThresholdMilliseconds, ") is greater than the session timeout (").concat(this.sessionManager.sessionTimeoutMs, "). Session will never be detected as idle"));
  }
  startIfEnabledOrStop(e) {
    this.isRecordingEnabled ? (this._startCapture(e), ae(t, "beforeunload", this._onBeforeUnload), ae(t, "offline", this._onOffline), ae(t, "online", this._onOnline), ae(t, "visibilitychange", this._onVisibilityChange), this._setupSampling(), this._addEventTriggerListener(), A(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        if ("$pageview" === e.event) {
          var t = null != e && e.properties.$current_url ? this._maskUrl(null == e ? void 0 : e.properties.$current_url) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        cs.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var s, r, n, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (s = this.instance) || void 0 === s || null === (r = s.persistence) || void 0 === r || r.unregister(Ce), null === (n = this.instance) || void 0 === n || null === (o = n.persistence) || void 0 === o || o.unregister(Pe));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var e, i, s, r;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == t || t.removeEventListener("beforeunload", this._onBeforeUnload), null == t || t.removeEventListener("offline", this._onOffline), null == t || t.removeEventListener("online", this._onOnline), null == t || t.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (e = this._removePageViewCaptureHook) || void 0 === e || e.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._removeEventTriggerCaptureHook) || void 0 === i || i.call(this), this._removeEventTriggerCaptureHook = void 0, null === (s = this._onSessionIdListener) || void 0 === s || s.call(this), this._onSessionIdListener = void 0, null === (r = this._samplingSessionListener) || void 0 === r || r.call(this), this._samplingSessionListener = void 0, cs.info("stopped"));
  }
  makeSamplingDecision(e) {
    var t,
      i = this.sessionId !== e,
      s = this.sampleRate;
    if (O(s)) {
      var r = this.isSampled,
        n = i || !M(r),
        o = n ? as(e, s) : r;
      n && (o ? this._reportStarted("sampled") : cs.warn("Sample rate (".concat(s, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
        sampleRate: s,
        isSampled: o
      })), null === (t = this.instance.persistence) || void 0 === t || t.register({
        [Ie]: o
      });
    } else {
      var a;
      null === (a = this.instance.persistence) || void 0 === a || a.register({
        [Ie]: null
      });
    }
  }
  onRemoteConfig(e) {
    var t, i, s, r, n, o;
    (this._tryAddCustomEvent("$remote_config_received", e), this._persistRemoteConfig(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.endpoint);
    if (this._setupSampling(), !A(this._linkedFlag) && !this._linkedFlagSeen) {
      var a = R(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        l = R(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((e, t) => {
        var i = P(t) && a in t,
          s = l ? t[a] === l : i;
        s && this._reportStarted("linked_flag_matched", {
          linkedFlag: a,
          linkedVariant: l
        }), this._linkedFlagSeen = s;
      });
    }
    null !== (s = e.sessionRecording) && void 0 !== s && s.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), null !== (r = e.sessionRecording) && void 0 !== r && r.urlBlocklist && (this._urlBlocklist = e.sessionRecording.urlBlocklist), null !== (n = e.sessionRecording) && void 0 !== n && n.eventTriggers && (this._eventTriggers = e.sessionRecording.eventTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    O(this.sampleRate) && A(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistRemoteConfig(e) {
    if (this.instance.persistence) {
      var t,
        i = this.instance.persistence,
        s = () => {
          var t,
            s,
            r,
            n,
            o,
            a,
            l,
            c,
            u,
            d = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            h = A(d) ? null : parseFloat(d),
            _ = null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.minimumDurationMilliseconds;
          i.register({
            [fe]: !!e.sessionRecording,
            [me]: null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.consoleLogRecordingEnabled,
            [ye]: W({
              capturePerformance: e.capturePerformance
            }, null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.networkPayloadCapture),
            [be]: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.masking,
            [we]: {
              enabled: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.recordCanvas,
              fps: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasFps,
              quality: null === (c = e.sessionRecording) || void 0 === c ? void 0 : c.canvasQuality
            },
            [Se]: h,
            [ke]: F(_) ? null : _,
            [Ee]: null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.scriptConfig
          });
        };
      s(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(s);
    }
  }
  log(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!F(Object.assign) && !F(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), this.rrwebRecord) this._onScriptLoaded();else null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, this.scriptName, e => {
        if (e) return cs.error("could not load recorder", e);
        this._onScriptLoaded();
      });
      cs.info("starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  get scriptName() {
    var e, t, i;
    return (null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t || null === (i = t.get_property(Ee)) || void 0 === i ? void 0 : i.script) || "recorder";
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== ds.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    var t = this.isInteractiveEvent(e);
    t || this.isIdle || e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
      eventTimestamp: e.timestamp,
      lastActivityTimestamp: this._lastActivityTimestamp,
      threshold: this.sessionIdleThresholdMilliseconds,
      bufferLength: this.buffer.data.length,
      bufferSize: this.buffer.size
    }), this._flushBuffer());
    var i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
      reason: "user activity",
      type: e.type
    }), i = !0)), !this.isIdle) {
      var {
          windowId: s,
          sessionId: r
        } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
        n = this.sessionId !== r,
        o = this.windowId !== s;
      this.windowId = s, this.sessionId = r, n || o ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
    }
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : cs.warn("could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(hs(() => this.rrwebRecord.addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(hs(() => this.rrwebRecord.takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e,
      t,
      i = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      s = this.instance.config.session_recording;
    for (var [r, n] of Object.entries(s || {})) r in i && ("maskInputOptions" === r ? i.maskInputOptions = W({
      password: !0
    }, n) : i[r] = n);
    (this.canvasRecording && this.canvasRecording.enabled && (i.recordCanvas = !0, i.sampling = {
      canvas: this.canvasRecording.fps
    }, i.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), this.masking) && (i.maskAllInputs = this.masking.maskAllInputs, i.maskTextSelector = null !== (t = this.masking.maskTextSelector) && void 0 !== t ? t : void 0);
    if (this.rrwebRecord) {
      this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new Si(this.rrwebRecord, {
        refillRate: this.instance.config.session_recording.__mutationRateLimiterRefillRate,
        bucketSize: this.instance.config.session_recording.__mutationRateLimiterBucketSize,
        onBlockedNode: (e, t) => {
          var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
          cs.info(i, {
            node: t
          }), this.log(ls + " " + i, "warn");
        }
      });
      var o = this._gatherRRWebPlugins();
      this.stopRrweb = this.rrwebRecord(W({
        emit: e => {
          this.onRRwebEmit(e);
        },
        plugins: o
      }, i)), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
        sessionRecordingOptions: i,
        activePlugins: o.map(e => null == e ? void 0 : e.name)
      }), this._tryAddCustomEvent("$posthog_config", {
        config: this.instance.config
      });
    } else cs.error("onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), !this.isIdle) {
      var e = this.fullSnapshotIntervalMillis;
      e && (this._fullSnapshotTimer = setInterval(() => {
        this._tryTakeFullSnapshot();
      }, e));
    }
  }
  _gatherRRWebPlugins() {
    var e,
      t,
      i,
      s,
      r = [],
      n = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    n && this.isConsoleLogCaptureEnabled && r.push(n());
    var o = null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.rrwebPlugins) || void 0 === s ? void 0 : s.getRecordNetworkPlugin;
    this.networkPayloadCapture && I(o) && (!wt.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? r.push(o(bi(this.instance.config, this.networkPayloadCapture))) : cs.info("NetworkCapture not started because we are on localhost."));
    return r;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), e && P(e)) {
      if (e.type === ui.Meta) {
        var i = this._maskUrl(e.data.href);
        if (this._lastHref = i, !i) return;
        e.data.href = i;
      } else this._pageViewFallBack();
      if (this._checkUrlTriggerConditions(), !this._urlBlocked || function (e) {
        return e.type === ui.Custom && "recording paused" === e.data.tag;
      }(e)) {
        e.type === ui.FullSnapshot && this._scheduleFullSnapshot(), e.type === ui.FullSnapshot && "trigger_pending" === this.triggerStatus && this.clearBuffer();
        var s = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
        if (s) {
          var r = function (e) {
            var t = e;
            if (t && P(t) && 6 === t.type && P(t.data) && "rrweb/console@1" === t.data.plugin) {
              t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
              for (var i = [], s = 0; s < t.data.payload.payload.length; s++) t.data.payload.payload[s] && t.data.payload.payload[s].length > 2e3 ? i.push(t.data.payload.payload[s].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[s]);
              return t.data.payload.payload = i, e;
            }
            return e;
          }(s);
          if (this._updateWindowAndSessionIds(r), !this.isIdle || ps(r)) {
            if (ps(r)) {
              var n = r.data.payload;
              if (n) {
                var o = n.lastActivityTimestamp,
                  a = n.threshold;
                r.timestamp = o + a;
              }
            }
            var l = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
                if (li(e) < 1024) return e;
                try {
                  if (e.type === ui.FullSnapshot) return W(W({}, e), {}, {
                    data: _s(e.data),
                    cv: "2024-10"
                  });
                  if (e.type === ui.IncrementalSnapshot && e.data.source === di.Mutation) return W(W({}, e), {}, {
                    cv: "2024-10",
                    data: W(W({}, e.data), {}, {
                      texts: _s(e.data.texts),
                      attributes: _s(e.data.attributes),
                      removes: _s(e.data.removes),
                      adds: _s(e.data.adds)
                    })
                  });
                  if (e.type === ui.IncrementalSnapshot && e.data.source === di.StyleSheetRule) return W(W({}, e), {}, {
                    cv: "2024-10",
                    data: W(W({}, e.data), {}, {
                      adds: e.data.adds ? _s(e.data.adds) : void 0,
                      removes: e.data.removes ? _s(e.data.removes) : void 0
                    })
                  });
                } catch (e) {
                  cs.error("could not compress event - will use uncompressed event", e);
                }
                return e;
              }(r) : r,
              c = {
                $snapshot_bytes: li(l),
                $snapshot_data: l,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
            "disabled" !== this.status ? this._captureSnapshotBuffered(c) : this.clearBuffer();
          }
        }
      }
    }
  }
  _pageViewFallBack() {
    if (!this.instance.config.capture_pageview && t) {
      var e = this._maskUrl(t.location.href);
      this._lastHref !== e && (this._tryAddCustomEvent("$url_changed", {
        href: e
      }), this._lastHref = e);
    }
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      var e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    var t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i,
        s = {
          url: e
        };
      return null === (i = s = t.maskNetworkRequestFn(s)) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    var e = this.minimumDuration,
      t = this.sessionDuration,
      i = O(t) && t >= 0,
      s = O(e) && i && t < e;
    if ("buffering" === this.status || "paused" === this.status || "disabled" === this.status || s) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    this.buffer.data.length > 0 && ci(this.buffer).forEach(e => {
      this._captureSnapshot({
        $snapshot_bytes: e.size,
        $snapshot_data: e.data,
        $session_id: e.sessionId,
        $window_id: e.windowId,
        $lib: "web",
        $lib_version: p.LIB_VERSION
      });
    });
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t,
      i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTriggerConditions() {
    if (void 0 !== t && t.location.href) {
      var e = t.location.href,
        i = this._urlBlocked,
        s = gs(e, this._urlBlocklist);
      s && !i ? this._pauseRecording() : !s && i && this._resumeRecording(), gs(e, this._urlTriggers) && this._activateTrigger("url");
    }
  }
  _activateTrigger(e) {
    var t, i;
    "trigger_pending" === this.triggerStatus && (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      ["url" === e ? Pe : Ce]: this.sessionId
    }), this._flushBuffer(), this._reportStarted(e + "_trigger_matched"));
  }
  _pauseRecording() {
    this._urlBlocked || (this._urlBlocked = !0, clearInterval(this._fullSnapshotTimer), cs.info("recording paused due to URL blocker"), this._tryAddCustomEvent("recording paused", {
      reason: "url blocker"
    }));
  }
  _resumeRecording() {
    this._urlBlocked && (this._urlBlocked = !1, this._tryTakeFullSnapshot(), this._scheduleFullSnapshot(), this._tryAddCustomEvent("recording resumed", {
      reason: "left blocked url"
    }), cs.info("recording resumed"));
  }
  _addEventTriggerListener() {
    0 !== this._eventTriggers.length && A(this._removeEventTriggerCaptureHook) && (this._removeEventTriggerCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        this._eventTriggers.includes(e.event) && this._activateTrigger("event");
      } catch (e) {
        cs.error("Could not activate event trigger", e);
      }
    }));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._tryTakeFullSnapshot(), this._reportStarted("linked_flag_overridden");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [Ie]: !0
    }), this._tryTakeFullSnapshot(), this._reportStarted("sampling_overridden");
  }
  overrideTrigger(e) {
    this._activateTrigger(e);
  }
  _reportStarted(e, t) {
    this.instance.register_for_session({
      $session_recording_start_reason: e
    }), cs.info(e.replace("_", " "), t), m(["recording_initialized", "session_id_changed"], e) || this._tryAddCustomEvent(e, t);
  }
}
var fs = H("[SegmentIntegration]");
function ms(e, t) {
  var i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    var i = e.config.segment;
    if (!i) return t();
    var s = i => {
        var s = () => i.anonymousId() || Nt();
        e.config.get_device_id = s, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: s()
        }), e.persistence.set_property(Le, "identified")), t();
      },
      r = i.user();
    "then" in r && I(r.then) ? r.then(e => s(e)) : s(r);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || fs.warn("This browser does not have Promise support, and can not use the segment integration");
      var t = (t, i) => {
        var s;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || (fs.info("No userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && (fs.info("UserId set, identifying with PostHog"), e.identify(t.event.userId));
        var r = e._calculate_event_properties(i, null !== (s = t.event.properties) && void 0 !== s ? s : {}, new Date());
        return t.event.properties = Object.assign({}, r, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
var ys = "posthog-js";
function bs(e) {
  var {
    organization: t,
    projectId: i,
    prefix: s,
    severityAllowList: r = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return n => {
    var o, a, l, c, u;
    if (!("*" === r || r.includes(n.level)) || !e.__loaded) return n;
    n.tags || (n.tags = {});
    var d = e.requestRouter.endpointFor("ui", "/project/".concat(e.config.token, "/person/").concat(e.get_distinct_id()));
    n.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (n.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    var h = (null === (o = n.exception) || void 0 === o ? void 0 : o.values) || [],
      _ = h.map(e => W(W({}, e), {}, {
        stacktrace: e.stacktrace ? W(W({}, e.stacktrace), {}, {
          type: "raw",
          frames: (e.stacktrace.frames || []).map(e => W(W({}, e), {}, {
            platform: "web:javascript"
          }))
        }) : void 0
      })),
      p = {
        $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || n.message,
        $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
        $exception_personURL: d,
        $exception_level: n.level,
        $exception_list: _,
        $sentry_event_id: n.event_id,
        $sentry_exception: n.exception,
        $sentry_exception_message: (null === (c = h[0]) || void 0 === c ? void 0 : c.value) || n.message,
        $sentry_exception_type: null === (u = h[0]) || void 0 === u ? void 0 : u.type,
        $sentry_tags: n.tags
      };
    return t && i && (p.$sentry_url = (s || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + n.event_id), e.exceptions.sendExceptionEvent(p), n;
  };
}
class ws {
  constructor(e, t, i, s, r) {
    this.name = ys, this.setupOnce = function (n) {
      n(bs(e, {
        organization: t,
        projectId: i,
        prefix: s,
        severityAllowList: r
      }));
    };
  }
}
var Ss,
  ks = null != t && t.location ? It(t.location.hash, "__posthog") || It(location.hash, "state") : null,
  Es = "_postHogToolbarParams",
  xs = H("[Toolbar]");
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(Ss || (Ss = {}));
class Is {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    _.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = _.ph_toolbar_state) && void 0 !== e ? e : Ss.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var e,
      i,
      s = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!t || !a) return !1;
    s = null !== (e = s) && void 0 !== e ? e : t.location, n = null !== (i = n) && void 0 !== i ? i : t.history;
    try {
      if (!r) {
        try {
          t.localStorage.setItem("test", "test"), t.localStorage.removeItem("test");
        } catch (e) {
          return !1;
        }
        r = null == t ? void 0 : t.localStorage;
      }
      var o,
        l = ks || It(s.hash, "__posthog") || It(s.hash, "state"),
        c = l ? ee(() => JSON.parse(atob(decodeURIComponent(l)))) || ee(() => JSON.parse(decodeURIComponent(l))) : null;
      return c && "ph_authorize" === c.action ? ((o = c).source = "url", o && Object.keys(o).length > 0 && (c.desiredHash ? s.hash = c.desiredHash : n ? n.replaceState(n.state, "", s.pathname + s.search) : s.hash = "")) : ((o = JSON.parse(r.getItem(Es) || "{}")).source = "localstorage", delete o.userIntent), !(!o.token || this.instance.config.token !== o.token) && (this.loadToolbar(o), !0);
    } catch (e) {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    var t = _.ph_load_toolbar || _.ph_load_editor;
    !A(t) && I(t) ? t(e, this.instance) : xs.warn("No toolbar load function found");
  }
  loadToolbar(e) {
    var i = !(null == a || !a.getElementById(ze));
    if (!t || i) return !1;
    var s = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      r = W(W({
        token: this.instance.config.token
      }, e), {}, {
        apiURL: this.instance.requestRouter.endpointFor("ui")
      }, s ? {
        instrument: !1
      } : {});
    if (t.localStorage.setItem(Es, JSON.stringify(W(W({}, r), {}, {
      source: void 0
    }))), this.getToolbarState() === Ss.LOADED) this._callLoadToolbar(r);else if (this.getToolbarState() === Ss.UNINITIALIZED) {
      var n, o;
      this.setToolbarState(Ss.LOADING), null === (n = _.__PosthogExtensions__) || void 0 === n || null === (o = n.loadExternalDependency) || void 0 === o || o.call(n, this.instance, "toolbar", e => {
        if (e) return xs.error("[Toolbar] Failed to load", e), void this.setToolbarState(Ss.UNINITIALIZED);
        this.setToolbarState(Ss.LOADED), this._callLoadToolbar(r);
      }), ae(t, "turbolinks:load", () => {
        this.setToolbarState(Ss.UNINITIALIZED), this.loadToolbar(r);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
var Ps = H("[TracingHeaders]");
class Cs {
  constructor(e) {
    V(this, "_restoreXHRPatch", void 0), V(this, "_restoreFetchPatch", void 0), V(this, "_startCapturing", () => {
      var e, t, i, s;
      F(this._restoreXHRPatch) && (null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
      F(this._restoreFetchPatch) && (null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.tracingHeadersPatchFns) || void 0 === s || s._patchFetch(this.instance.sessionManager));
    }), this.instance = e;
  }
  _loadScript(e) {
    var t, i, s;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "tracing-headers", t => {
      if (t) return Ps.error("failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
}
var Fs = H("[Web Vitals]"),
  Rs = 9e5;
class Ts {
  constructor(e) {
    var t;
    V(this, "_enabledServerSide", !1), V(this, "_initialized", !1), V(this, "buffer", {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    }), V(this, "_flushToCapture", () => {
      clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => W(W({}, e), {}, {
        ["$web_vitals_".concat(t.name, "_event")]: W({}, t),
        ["$web_vitals_".concat(t.name, "_value")]: t.value
      }), {})), this.buffer = {
        url: void 0,
        metrics: [],
        firstMetricTimestamp: void 0
      });
    }), V(this, "_addToBuffer", e => {
      var t,
        i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
      if (F(i)) Fs.error("Could not read session ID. Dropping metrics!");else {
        this.buffer = this.buffer || {
          url: void 0,
          metrics: [],
          firstMetricTimestamp: void 0
        };
        var s = this._currentURL();
        if (!F(s)) if (A(null == e ? void 0 : e.name) || A(null == e ? void 0 : e.value)) Fs.error("Invalid metric received", e);else if (this._maxAllowedValue && e.value >= this._maxAllowedValue) Fs.error("Ignoring metric with value >= " + this._maxAllowedValue, e);else this.buffer.url !== s && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), F(this.buffer.url) && (this.buffer.url = s), this.buffer.firstMetricTimestamp = F(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push(W(W({}, e), {}, {
          $current_url: s,
          $session_id: i.sessionId,
          $window_id: i.windowId,
          timestamp: Date.now()
        })), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
      }
    }), V(this, "_startCapturing", () => {
      var e,
        t,
        i,
        s,
        r = _.__PosthogExtensions__;
      F(r) || F(r.postHogWebVitalsCallbacks) || ({
        onLCP: e,
        onCLS: t,
        onFCP: i,
        onINP: s
      } = r.postHogWebVitalsCallbacks), e && t && i && s ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && s(this._addToBuffer.bind(this)), this._initialized = !0) : Fs.error("web vitals callbacks not loaded - not starting");
    }), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[pe]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e,
      t,
      i = P(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return F(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[ve]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (P(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    var e = P(this.instance.config.capture_performance) && O(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : Rs;
    return 0 < e && e <= 6e4 ? Rs : e;
  }
  get isEnabled() {
    var e = P(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return M(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (Fs.info("enabled, starting..."), this.loadScript(this._startCapturing));
  }
  onRemoteConfig(e) {
    var t = P(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = P(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [pe]: t
    }), this.instance.persistence.register({
      [ve]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, s;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "web-vitals", t => {
      t ? Fs.error("failed to load script", t) : e();
    });
  }
  _currentURL() {
    var e = t ? t.location.href : void 0;
    return e || Fs.error("Could not determine current URL"), e;
  }
}
var $s = H("[Heatmaps]");
function As(e) {
  return P(e) && "clientX" in e && "clientY" in e && O(e.clientX) && O(e.clientY);
}
class Os {
  constructor(e) {
    var t;
    V(this, "rageclicks", new bt()), V(this, "_enabledServerSide", !1), V(this, "_initialized", !1), V(this, "_flushInterval", null), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[he]);
  }
  get flushIntervalMilliseconds() {
    var e = 5e3;
    return P(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return F(this.instance.config.capture_heatmaps) ? F(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      $s.info("starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e, t;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), null === (t = this.deadClicksCapture) || void 0 === t || t.stop(), this.getAndClearBuffer();
    }
  }
  onRemoteConfig(e) {
    var t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [he]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    var e = this.buffer;
    return this.buffer = void 0, e;
  }
  _onDeadClick(e) {
    this._onClick(e.originalEvent, "deadclick");
  }
  _setupListeners() {
    t && a && (ae(t, "beforeunload", this.flush.bind(this)), ae(a, "click", e => this._onClick(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), ae(a, "mousemove", e => this._onMouseMove(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), this.deadClicksCapture = new ni(this.instance, si, this._onDeadClick.bind(this)), this.deadClicksCapture.startIfEnabled(), this._initialized = !0);
  }
  _getProperties(e, i) {
    var s = this.instance.scrollManager.scrollY(),
      r = this.instance.scrollManager.scrollX(),
      n = this.instance.scrollManager.scrollElement(),
      o = function (e, i, s) {
        for (var r = e; r && Ge(r) && !Je(r, "body");) {
          if (r === s) return !1;
          if (m(i, null == t ? void 0 : t.getComputedStyle(r).position)) return !0;
          r = rt(r);
        }
        return !1;
      }(it(e), ["fixed", "sticky"], n);
    return {
      x: e.clientX + (o ? 0 : r),
      y: e.clientY + (o ? 0 : s),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "click";
    if (!Ve(e.target) && As(e)) {
      var s = this._getProperties(e, i);
      null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture(W(W({}, s), {}, {
        type: "rageclick"
      })), this._capture(s);
    }
  }
  _onMouseMove(e) {
    !Ve(e.target) && As(e) && (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(e) {
    if (t) {
      var i = t.location.href;
      this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(e);
    }
  }
  flush() {
    this.buffer && !C(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class Ms {
  constructor(e) {
    this._instance = e;
  }
  doPageView(e, i) {
    var s,
      r = this._previousPageViewProperties(e, i);
    return this._currentPageview = {
      pathname: null !== (s = null == t ? void 0 : t.location.pathname) && void 0 !== s ? s : "",
      pageViewId: i,
      timestamp: e
    }, this._instance.scrollManager.resetContext(), r;
  }
  doPageLeave(e) {
    var t;
    return this._previousPageViewProperties(e, null === (t = this._currentPageview) || void 0 === t ? void 0 : t.pageViewId);
  }
  doEvent() {
    var e;
    return {
      $pageview_id: null === (e = this._currentPageview) || void 0 === e ? void 0 : e.pageViewId
    };
  }
  _previousPageViewProperties(e, t) {
    var i = this._currentPageview;
    if (!i) return {
      $pageview_id: t
    };
    var s = {
        $pageview_id: t,
        $prev_pageview_id: i.pageViewId
      },
      r = this._instance.scrollManager.getContext();
    if (r && !this._instance.config.disable_scroll_properties) {
      var {
        maxScrollHeight: n,
        lastScrollY: o,
        maxScrollY: a,
        maxContentHeight: l,
        lastContentY: c,
        maxContentY: u
      } = r;
      if (!(F(n) || F(o) || F(a) || F(l) || F(c) || F(u))) {
        n = Math.ceil(n), o = Math.ceil(o), a = Math.ceil(a), l = Math.ceil(l), c = Math.ceil(c), u = Math.ceil(u);
        var d = n <= 1 ? 1 : wi(o / n, 0, 1),
          h = n <= 1 ? 1 : wi(a / n, 0, 1),
          _ = l <= 1 ? 1 : wi(c / l, 0, 1),
          p = l <= 1 ? 1 : wi(u / l, 0, 1);
        s = X(s, {
          $prev_pageview_last_scroll: o,
          $prev_pageview_last_scroll_percentage: d,
          $prev_pageview_max_scroll: a,
          $prev_pageview_max_scroll_percentage: h,
          $prev_pageview_last_content: c,
          $prev_pageview_last_content_percentage: _,
          $prev_pageview_max_content: u,
          $prev_pageview_max_content_percentage: p
        });
      }
    }
    return i.pathname && (s.$prev_pageview_pathname = i.pathname), i.timestamp && (s.$prev_pageview_duration = (e.getTime() - i.timestamp.getTime()) / 1e3), s;
  }
}
class Ls {
  constructor(e) {
    this.instance = e;
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent"
    });
  }
}
var Ds,
  qs = H("[FeatureFlags]"),
  Ns = "$active_feature_flags",
  Bs = "$override_feature_flags",
  Hs = "$feature_flag_payloads",
  Us = "$override_feature_flag_payloads",
  zs = "$feature_flag_request_id",
  js = e => {
    var t = {};
    for (var [i, s] of Z(e || {})) s && (t[i] = s);
    return t;
  };
!function (e) {
  e.FeatureFlags = "feature_flags", e.Recordings = "recordings";
}(Ds || (Ds = {}));
class Ws {
  constructor(e) {
    V(this, "_override_warning", !1), V(this, "_hasLoadedFlags", !1), V(this, "_requestInFlight", !1), V(this, "_reloadingDisabled", !1), V(this, "_additionalReloadRequested", !1), V(this, "_decideCalled", !1), V(this, "_flagsLoadedFromRemote", !1), this.instance = e, this.featureFlagEventHandlers = [];
  }
  decide() {
    if (this.instance.config.__preview_remote_config) this._decideCalled = !0;else {
      var e = !this._reloadDebouncer && (this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load);
      this._callDecideEndpoint({
        disableFlags: e
      });
    }
  }
  get hasLoadedFlags() {
    return this._hasLoadedFlags;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagVariants() {
    var e = this.instance.get_property(Fe),
      t = this.instance.get_property(Bs);
    if (!t) return e || {};
    for (var i = X({}, e), s = Object.keys(t), r = 0; r < s.length; r++) i[s[r]] = t[s[r]];
    return this._override_warning || (qs.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    var e = this.instance.get_property(Hs),
      t = this.instance.get_property(Us);
    if (!t) return e || {};
    for (var i = X({}, e || {}), s = Object.keys(t), r = 0; r < s.length; r++) i[s[r]] = t[s[r]];
    return this._override_warning || (qs.warn(" Overriding feature flag payloads!", {
      flagPayloads: e,
      overriddenPayloads: t,
      finalPayloads: i
    }), this._override_warning = !0), i;
  }
  reloadFeatureFlags() {
    this._reloadingDisabled || this.instance.config.advanced_disable_feature_flags || this._reloadDebouncer || (this._reloadDebouncer = setTimeout(() => {
      this._callDecideEndpoint();
    }, 5));
  }
  clearDebouncer() {
    clearTimeout(this._reloadDebouncer), this._reloadDebouncer = void 0;
  }
  ensureFlagsLoaded() {
    this._hasLoadedFlags || this._requestInFlight || this._reloadDebouncer || this.reloadFeatureFlags();
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this._reloadingDisabled = e;
  }
  _callDecideEndpoint(t) {
    var i;
    if (this.clearDebouncer(), !this.instance.config.advanced_disable_decide) if (this._requestInFlight) this._additionalReloadRequested = !0;else {
      var s = {
        token: this.instance.config.token,
        distinct_id: this.instance.get_distinct_id(),
        groups: this.instance.getGroups(),
        $anon_distinct_id: this.$anon_distinct_id,
        person_properties: W(W({}, (null === (i = this.instance.persistence) || void 0 === i ? void 0 : i.get_initial_props()) || {}), this.instance.get_property(Te) || {}),
        group_properties: this.instance.get_property($e)
      };
      (null != t && t.disableFlags || this.instance.config.advanced_disable_feature_flags) && (s.disable_flags = !0), this._requestInFlight = !0, this.instance._send_request({
        method: "POST",
        url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
        data: s,
        compression: this.instance.config.disable_compression ? void 0 : e.Base64,
        timeout: this.instance.config.feature_flag_request_timeout_ms,
        callback: e => {
          var t,
            i,
            r,
            n = !0;
          (200 === e.statusCode && (this._additionalReloadRequested || (this.$anon_distinct_id = void 0), n = !1), this._requestInFlight = !1, this._decideCalled) || (this._decideCalled = !0, this.instance._onRemoteConfig(null !== (r = e.json) && void 0 !== r ? r : {}));
          s.disable_flags && !this._additionalReloadRequested || (this._flagsLoadedFromRemote = !n, e.json && null !== (t = e.json.quotaLimited) && void 0 !== t && t.includes(Ds.FeatureFlags) ? qs.warn("You have hit your feature flags quota limit, and will not be able to load feature flags until the quota is reset.  Please visit https://posthog.com/docs/billing/limits-alerts to learn more.") : (this.receivedFeatureFlags(null !== (i = e.json) && void 0 !== i ? i : {}, n), this._additionalReloadRequested && (this._additionalReloadRequested = !1, this._callDecideEndpoint())));
        }
      });
    }
  }
  getFeatureFlag(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) {
      var i,
        s,
        r,
        n,
        o,
        a = this.getFlagVariants()[e],
        l = "".concat(a),
        c = this.instance.get_property(zs) || void 0,
        u = this.instance.get_property(Me) || {};
      if (t.send_event || !("send_event" in t)) if (!(e in u) || !u[e].includes(l)) x(u[e]) ? u[e].push(l) : u[e] = [l], null === (i = this.instance.persistence) || void 0 === i || i.register({
        [Me]: u
      }), this.instance.capture("$feature_flag_called", {
        $feature_flag: e,
        $feature_flag_response: a,
        $feature_flag_payload: this.getFeatureFlagPayload(e) || null,
        $feature_flag_request_id: c,
        $feature_flag_bootstrapped_response: (null === (s = this.instance.config.bootstrap) || void 0 === s || null === (r = s.featureFlags) || void 0 === r ? void 0 : r[e]) || null,
        $feature_flag_bootstrapped_payload: (null === (n = this.instance.config.bootstrap) || void 0 === n || null === (o = n.featureFlagPayloads) || void 0 === o ? void 0 : o[e]) || null,
        $used_bootstrap_value: !this._flagsLoadedFromRemote
      });
      return a;
    }
    qs.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  getRemoteConfigPayload(t, i) {
    var s = this.instance.config.token;
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
      data: {
        distinct_id: this.instance.get_distinct_id(),
        token: s
      },
      compression: this.instance.config.disable_compression ? void 0 : e.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => {
        var s,
          r = null === (s = e.json) || void 0 === s ? void 0 : s.featureFlagPayloads;
        i((null == r ? void 0 : r[t]) || void 0);
      }
    });
  }
  isFeatureEnabled(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    qs.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (this.instance.persistence) {
      this._hasLoadedFlags = !0;
      var i = this.getFlagVariants(),
        s = this.getFlagPayloads();
      !function (e, t) {
        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
          s = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          r = e.featureFlags,
          n = e.featureFlagPayloads,
          o = e.requestId;
        if (r) if (x(r)) {
          var a = {};
          if (r) for (var l = 0; l < r.length; l++) a[r[l]] = !0;
          t && t.register({
            [Ns]: r,
            [Fe]: a
          });
        } else {
          var c = r,
            u = n;
          e.errorsWhileComputingFlags && (c = W(W({}, i), c), u = W(W({}, s), u)), t && t.register(W({
            [Ns]: Object.keys(js(c)),
            [Fe]: c || {},
            [Hs]: u || {}
          }, o ? {
            [zs]: o
          } : {}));
        }
      }(e, this.instance.persistence, i, s), this._fireFeatureFlagsCallbacks(t);
    }
  }
  override(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    qs.warn("override is deprecated. Please use overrideFeatureFlags instead."), this.overrideFeatureFlags({
      flags: e,
      suppressWarning: t
    });
  }
  overrideFeatureFlags(e) {
    if (!this.instance.__loaded || !this.instance.persistence) return qs.uninitializedWarning("posthog.feature_flags.overrideFeatureFlags");
    if (!1 === e) return this.instance.persistence.unregister(Bs), this.instance.persistence.unregister(Us), void this._fireFeatureFlagsCallbacks();
    if (e && "object" == typeof e && ("flags" in e || "payloads" in e)) {
      var t,
        i = e;
      if (this._override_warning = Boolean(null !== (t = i.suppressWarning) && void 0 !== t && t), "flags" in i) if (!1 === i.flags) this.instance.persistence.unregister(Bs);else if (i.flags) if (x(i.flags)) {
        for (var s = {}, r = 0; r < i.flags.length; r++) s[i.flags[r]] = !0;
        this.instance.persistence.register({
          [Bs]: s
        });
      } else this.instance.persistence.register({
        [Bs]: i.flags
      });
      return "payloads" in i && (!1 === i.payloads ? this.instance.persistence.unregister(Us) : i.payloads && this.instance.persistence.register({
        [Us]: i.payloads
      })), void this._fireFeatureFlagsCallbacks();
    }
    this._fireFeatureFlagsCallbacks();
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this._hasLoadedFlags) {
      var {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i,
      s = (this.instance.get_property(Re) || []).find(t => t.flagKey === e),
      r = {
        ["$feature_enrollment/".concat(e)]: t
      },
      n = {
        $feature_flag: e,
        $feature_enrollment: t,
        $set: r
      };
    s && (n.$early_access_feature_name = s.name), this.instance.capture("$feature_enrollment_update", n), this.setPersonPropertiesForFlags(r, !1);
    var o = W(W({}, this.getFlagVariants()), {}, {
      [e]: t
    });
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [Ns]: Object.keys(js(o)),
      [Fe]: o
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = this.instance.get_property(Re);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        var i;
        if (t.json) {
          var s = t.json.earlyAccessFeatures;
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Re]: s
          }), e(s);
        }
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    var e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    var {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(s => s(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Te) || {};
    this.instance.register({
      [Te]: W(W({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister(Te);
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property($e) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = W(W({}, i[t]), e[t]), delete e[t];
    }), this.instance.register({
      [$e]: W(W({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      var t = this.instance.get_property($e) || {};
      this.instance.register({
        [$e]: W(W({}, t), {}, {
          [e]: {}
        })
      });
    } else this.instance.unregister($e);
  }
}
var Vs = "Mobile",
  Gs = "iOS",
  Js = "Android",
  Ys = "Tablet",
  Ks = Js + " " + Ys,
  Xs = "iPad",
  Qs = "Apple",
  Zs = Qs + " Watch",
  er = "Safari",
  tr = "BlackBerry",
  ir = "Samsung",
  sr = ir + "Browser",
  rr = ir + " Internet",
  nr = "Chrome",
  or = nr + " OS",
  ar = nr + " " + Gs,
  lr = "Internet Explorer",
  cr = lr + " " + Vs,
  ur = "Opera",
  dr = ur + " Mini",
  hr = "Edge",
  _r = "Microsoft " + hr,
  pr = "Firefox",
  gr = pr + " " + Gs,
  vr = "Nintendo",
  fr = "PlayStation",
  mr = "Xbox",
  yr = Js + " " + Vs,
  br = Vs + " " + er,
  wr = "Windows",
  Sr = wr + " Phone",
  kr = "Nokia",
  Er = "Ouya",
  xr = "Generic",
  Ir = xr + " " + Vs.toLowerCase(),
  Pr = xr + " " + Ys.toLowerCase(),
  Cr = "Konqueror",
  Fr = "(\\d+(\\.\\d+)?)",
  Rr = new RegExp("Version/" + Fr),
  Tr = new RegExp(mr, "i"),
  $r = new RegExp(fr + " \\w+", "i"),
  Ar = new RegExp(vr + " \\w+", "i"),
  Or = new RegExp(tr + "|PlayBook|BB10", "i"),
  Mr = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var Lr = (e, t) => t && m(t, Qs) || function (e) {
    return m(e, er) && !m(e, nr) && !m(e, Js);
  }(e),
  Dr = function (e, t) {
    return t = t || "", m(e, " OPR/") && m(e, "Mini") ? dr : m(e, " OPR/") ? ur : Or.test(e) ? tr : m(e, "IE" + Vs) || m(e, "WPDesktop") ? cr : m(e, sr) ? rr : m(e, hr) || m(e, "Edg/") ? _r : m(e, "FBIOS") ? "Facebook " + Vs : m(e, "UCWEB") || m(e, "UCBrowser") ? "UC Browser" : m(e, "CriOS") ? ar : m(e, "CrMo") || m(e, nr) ? nr : m(e, Js) && m(e, er) ? yr : m(e, "FxiOS") ? gr : m(e.toLowerCase(), Cr.toLowerCase()) ? Cr : Lr(e, t) ? m(e, Vs) ? br : er : m(e, pr) ? pr : m(e, "MSIE") || m(e, "Trident/") ? lr : m(e, "Gecko") ? pr : "";
  },
  qr = {
    [cr]: [new RegExp("rv:" + Fr)],
    [_r]: [new RegExp(hr + "?\\/" + Fr)],
    [nr]: [new RegExp("(" + nr + "|CrMo)\\/" + Fr)],
    [ar]: [new RegExp("CriOS\\/" + Fr)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + Fr)],
    [er]: [Rr],
    [br]: [Rr],
    [ur]: [new RegExp("(Opera|OPR)\\/" + Fr)],
    [pr]: [new RegExp(pr + "\\/" + Fr)],
    [gr]: [new RegExp("FxiOS\\/" + Fr)],
    [Cr]: [new RegExp("Konqueror[:/]?" + Fr, "i")],
    [tr]: [new RegExp(tr + " " + Fr), Rr],
    [yr]: [new RegExp("android\\s" + Fr, "i")],
    [rr]: [new RegExp(sr + "\\/" + Fr)],
    [lr]: [new RegExp("(rv:|MSIE )" + Fr)],
    Mozilla: [new RegExp("rv:" + Fr)]
  },
  Nr = [[new RegExp(mr + "; " + mr + " (.*?)[);]", "i"), e => [mr, e && e[1] || ""]], [new RegExp(vr, "i"), [vr, ""]], [new RegExp(fr, "i"), [fr, ""]], [Or, [tr, ""]], [new RegExp(wr, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [Sr, ""];
    if (new RegExp(Vs).test(t) && !/IEMobile\b/.test(t)) return [wr + " " + Vs, ""];
    var i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      var s = i[1],
        r = Mr[s] || "";
      return /arm/i.test(t) && (r = "RT"), [wr, r];
    }
    return [wr, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [Gs, t.join(".")];
    }
    return [Gs, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    var t = "";
    return e && e.length >= 3 && (t = F(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + Js + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + Js + ")", "i"), e => {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [Js, t.join(".")];
    }
    return [Js, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [or, ""]], [/Linux|debian/i, ["Linux", ""]]],
  Br = function (e) {
    return Ar.test(e) ? vr : $r.test(e) ? fr : Tr.test(e) ? mr : new RegExp(Er, "i").test(e) ? Er : new RegExp("(" + Sr + "|WPDesktop)", "i").test(e) ? Sr : /iPad/.test(e) ? Xs : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? Zs : Or.test(e) ? tr : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(kr, "i").test(e) ? kr : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(Vs).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? Js : Ks : Js : new RegExp("(pda|" + Vs + ")", "i").test(e) ? Ir : new RegExp(Ys, "i").test(e) && !new RegExp(Ys + " pc", "i").test(e) ? Pr : "";
  },
  Hr = "https?://(.*)",
  Ur = ["gclid", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "igshid", "ttclid", "rdt_cid", "irclid", "_kx"],
  zr = Q(["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gad_source", "mc_cid"], Ur),
  jr = "<masked>",
  Wr = {
    campaignParams: function () {
      var {
        customTrackedParams: e,
        maskPersonalDataProperties: t,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!a) return {};
      var s = t ? Q([], Ur, i || []) : [];
      return this._campaignParamsFromUrl(xt(a.URL, s, jr), e);
    },
    _campaignParamsFromUrl: function (e, t) {
      var i = zr.concat(t || []),
        s = {};
      return K(i, function (t) {
        var i = Et(e, t);
        s[t] = i || null;
      }), s;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(Hr + "google.([^/?]*)") ? "google" : 0 === e.search(Hr + "bing.com") ? "bing" : 0 === e.search(Hr + "yahoo.com") ? "yahoo" : 0 === e.search(Hr + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      var t = Wr._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        s = {};
      if (!$(t)) {
        s.$search_engine = t;
        var r = a ? Et(a.referrer, i) : "";
        r.length && (s.ph_keyword = r);
      }
      return s;
    },
    searchInfo: function () {
      var e = null == a ? void 0 : a.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: Dr,
    browserVersion: function (e, t) {
      var i = Dr(e, t),
        s = qr[i];
      if (F(s)) return null;
      for (var r = 0; r < s.length; r++) {
        var n = s[r],
          o = e.match(n);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    browserLanguagePrefix: function () {
      var e = this.browserLanguage();
      return "string" == typeof e ? e.split("-")[0] : void 0;
    },
    os: function (e) {
      for (var t = 0; t < Nr.length; t++) {
        var [i, s] = Nr[t],
          r = i.exec(e),
          n = r && (I(s) ? s(r, e) : s);
        if (n) return n;
      }
      return ["", ""];
    },
    device: Br,
    deviceType: function (e) {
      var t = Br(e);
      return t === Xs || t === Ks || "Kobo" === t || "Kindle Fire" === t || t === Pr ? Ys : t === vr || t === mr || t === fr || t === Er ? "Console" : t === Zs ? "Wearable" : t ? Vs : "Desktop";
    },
    referrer: function () {
      return (null == a ? void 0 : a.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != a && a.referrer && (null === (e = St(a.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    personInfo: function () {
      var {
          maskPersonalDataProperties: e,
          customPersonalDataProperties: t
        } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        i = e ? Q([], Ur, t || []) : [],
        s = null == l ? void 0 : l.href.substring(0, 1e3);
      return {
        r: this.referrer().substring(0, 1e3),
        u: s ? xt(s, i, jr) : void 0
      };
    },
    personPropsFromInfo: function (e) {
      var t,
        {
          r: i,
          u: s
        } = e,
        r = {
          $referrer: i,
          $referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = St(i)) || void 0 === t ? void 0 : t.host
        };
      if (s) {
        r.$current_url = s;
        var n = St(s);
        r.$host = null == n ? void 0 : n.host, r.$pathname = null == n ? void 0 : n.pathname;
        var o = this._campaignParamsFromUrl(s);
        X(r, o);
      }
      if (i) {
        var a = this._searchInfoFromReferrer(i);
        X(r, a);
      }
      return r;
    },
    initialPersonPropsFromInfo: function (e) {
      var t = this.personPropsFromInfo(e),
        i = {};
      return K(t, function (e, t) {
        i["$initial_".concat(b(t))] = e;
      }), i;
    },
    timezone: function () {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return;
      }
    },
    timezoneOffset: function () {
      try {
        return new Date().getTimezoneOffset();
      } catch (e) {
        return;
      }
    },
    properties: function () {
      var {
        maskPersonalDataProperties: e,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!h) return {};
      var s = e ? Q([], Ur, i || []) : [],
        [r, n] = Wr.os(h);
      return X(ie({
        $os: r,
        $os_version: n,
        $browser: Wr.browser(h, navigator.vendor),
        $device: Wr.device(h),
        $device_type: Wr.deviceType(h),
        $timezone: Wr.timezone(),
        $timezone_offset: Wr.timezoneOffset()
      }), {
        $current_url: xt(null == l ? void 0 : l.href, s, jr),
        $host: null == l ? void 0 : l.host,
        $pathname: null == l ? void 0 : l.pathname,
        $raw_user_agent: h.length > 1e3 ? h.substring(0, 997) + "..." : h,
        $browser_version: Wr.browserVersion(h, navigator.vendor),
        $browser_language: Wr.browserLanguage(),
        $browser_language_prefix: Wr.browserLanguagePrefix(),
        $screen_height: null == t ? void 0 : t.screen.height,
        $screen_width: null == t ? void 0 : t.screen.width,
        $viewport_height: null == t ? void 0 : t.innerHeight,
        $viewport_width: null == t ? void 0 : t.innerWidth,
        $lib: "web",
        $lib_version: p.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!h) return {};
      var [e, t] = Wr.os(h);
      return X(ie({
        $os: e,
        $os_version: t,
        $browser: Wr.browser(h, navigator.vendor)
      }), {
        $browser_version: Wr.browserVersion(h, navigator.vendor)
      });
    }
  },
  Vr = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class Gr {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      var t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && B.info("Persistence loaded", e.persistence, W({}, this.props)), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    -1 === Vr.indexOf(e.persistence.toLowerCase()) && (B.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    var t = e.persistence.toLowerCase();
    return "localstorage" === t && Jt.is_supported() ? Jt : "localstorage+cookie" === t && Kt.is_supported() ? Kt : "sessionstorage" === t && ei.is_supported() ? ei : "memory" === t ? Qt : "cookie" === t ? Vt : Kt.is_supported() ? Kt : Vt;
  }
  properties() {
    var e = {};
    return K(this.props, function (t, i) {
      if (i === Fe && P(t)) for (var s = Object.keys(t), r = 0; r < s.length; r++) e["$feature/".concat(s[r])] = t[s[r]];else a = i, l = !1, ($(o = We) ? l : n && o.indexOf === n ? -1 != o.indexOf(a) : (K(o, function (e) {
        if (l || (l = e === a)) return J;
      }), l)) || (e[i] = t);
      var o, a, l;
    }), e;
  }
  load() {
    if (!this.disabled) {
      var e = this.storage.parse(this.name);
      e && (this.props = X({}, e));
    }
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (P(e)) {
      F(t) && (t = "None"), this.expire_days = F(i) ? this.default_expiry : i;
      var s = !1;
      if (K(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, s = !0);
      }), s) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (P(e)) {
      this.expire_days = F(t) ? this.default_expiry : t;
      var i = !1;
      if (K(e, (t, s) => {
        e.hasOwnProperty(s) && this.props[s] !== t && (this.props[s] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    if (!this.campaign_params_saved) {
      var e = Wr.campaignParams({
        customTrackedParams: this.config.custom_campaign_params,
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
      C(ie(e)) || this.register(e), this.campaign_params_saved = !0;
    }
  }
  update_search_keyword() {
    this.register(Wr.searchInfo());
  }
  update_referrer_info() {
    this.register_once(Wr.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[Ne] || this.props[Be] || this.register_once({
      [He]: Wr.personInfo({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      })
    }, void 0);
  }
  get_referrer_info() {
    return ie({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    var e = {};
    K([Be, Ne], t => {
      var i = this.props[t];
      i && K(i, function (t, i) {
        e["$initial_" + b(i)] = t;
      });
    });
    var t = this.props[He];
    if (t) {
      var i = Wr.initialPersonPropsFromInfo(t);
      X(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return K(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      var i = this.buildStorage(e),
        s = this.props;
      this.clear(), this.storage = i, this.props = s, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    var i = this.props[ue] || {};
    i[e] = t, this.props[ue] = i, this.save();
  }
  remove_event_timer(e) {
    var t = (this.props[ue] || {})[e];
    return F(t) || (delete this.props[ue][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
var Jr,
  Yr,
  Kr,
  Xr,
  Qr,
  Zr,
  en,
  tn,
  sn,
  rn,
  nn,
  on,
  an = {},
  ln = [],
  cn = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  un = Array.isArray;
function dn(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function hn(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function _n(e, t, i, s, r) {
  var n = {
    type: e,
    props: t,
    key: i,
    ref: s,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == r ? ++Kr : r,
    __i: -1,
    __u: 0
  };
  return null == r && null != Yr.vnode && Yr.vnode(n), n;
}
function pn(e) {
  return e.children;
}
function gn(e, t) {
  this.props = e, this.context = t;
}
function vn(e, t) {
  if (null == t) return e.__ ? vn(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? vn(e) : null;
}
function fn(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return fn(e);
  }
}
function mn(e) {
  (!e.__d && (e.__d = !0) && Xr.push(e) && !yn.__r++ || Qr !== Yr.debounceRendering) && ((Qr = Yr.debounceRendering) || Zr)(yn);
}
function yn() {
  var e, t, i, s, r, n, o, a, l;
  for (Xr.sort(en); e = Xr.shift();) e.__d && (t = Xr.length, s = void 0, n = (r = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((s = dn({}, r)).__v = r.__v + 1, Yr.vnode && Yr.vnode(s), Cn(o, s, r, i.__n, void 0 !== o.ownerSVGElement, 32 & r.__u ? [n] : null, a, null == n ? vn(r) : n, !!(32 & r.__u), l), s.__.__k[s.__i] = s, Fn(a, s, l), s.__e != n && fn(s)), Xr.length > t && Xr.sort(en));
  yn.__r = 0;
}
function bn(e, t, i, s, r, n, o, a, l, c, u) {
  var d,
    h,
    _,
    p,
    g,
    v = s && s.__k || ln,
    f = t.length;
  for (i.__d = l, wn(i, t, v), l = i.__d, d = 0; d < f; d++) null != (_ = i.__k[d]) && "boolean" != typeof _ && "function" != typeof _ && (h = -1 === _.__i ? an : v[_.__i] || an, _.__i = d, Cn(e, _, h, r, n, o, a, l, c, u), p = _.__e, _.ref && h.ref != _.ref && (h.ref && Tn(h.ref, null, _), u.push(_.ref, _.__c || p, _)), null == g && null != p && (g = p), 65536 & _.__u || h.__k === _.__k ? l = Sn(_, l, e) : "function" == typeof _.type && void 0 !== _.__d ? l = _.__d : p && (l = p.nextSibling), _.__d = void 0, _.__u &= -196609);
  i.__d = l, i.__e = g;
}
function wn(e, t, i) {
  var s,
    r,
    n,
    o,
    a,
    l = t.length,
    c = i.length,
    u = c,
    d = 0;
  for (e.__k = [], s = 0; s < l; s++) null != (r = e.__k[s] = null == (r = t[s]) || "boolean" == typeof r || "function" == typeof r ? null : "string" == typeof r || "number" == typeof r || "bigint" == typeof r || r.constructor == String ? _n(null, r, null, null, r) : un(r) ? _n(pn, {
    children: r
  }, null, null, null) : void 0 === r.constructor && r.__b > 0 ? _n(r.type, r.props, r.key, r.ref ? r.ref : null, r.__v) : r) ? (r.__ = e, r.__b = e.__b + 1, a = kn(r, i, o = s + d, u), r.__i = a, n = null, -1 !== a && (u--, (n = i[a]) && (n.__u |= 131072)), null == n || null === n.__v ? (-1 == a && d--, "function" != typeof r.type && (r.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? u > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== s + d && (r.__u |= 65536))) : (n = i[s]) && null == n.key && n.__e && (n.__e == e.__d && (e.__d = vn(n)), $n(n, n, !1), i[s] = null, u--);
  if (u) for (s = 0; s < c; s++) null != (n = i[s]) && 0 == (131072 & n.__u) && (n.__e == e.__d && (e.__d = vn(n)), $n(n, n));
}
function Sn(e, t, i) {
  var s, r;
  if ("function" == typeof e.type) {
    for (s = e.__k, r = 0; s && r < s.length; r++) s[r] && (s[r].__ = e, t = Sn(s[r], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function kn(e, t, i, s) {
  var r = e.key,
    n = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && r == l.key && n === l.type) return i;
  if (s > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && r == l.key && n === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && r == l.key && n === l.type) return a;
      a++;
    }
  }
  return -1;
}
function En(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || cn.test(t) ? i : i + "px";
}
function xn(e, t, i, s, r) {
  var n;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof s && (e.style.cssText = s = ""), s) for (t in s) i && t in i || En(e.style, t, "");
      if (i) for (t in i) s && i[t] === s[t] || En(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) n = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + n] = i, i ? s ? i.u = s.u : (i.u = Date.now(), e.addEventListener(t, n ? Pn : In, n)) : e.removeEventListener(t, n ? Pn : In, n);else {
    if (r) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function In(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(Yr.event ? Yr.event(e) : e);
}
function Pn(e) {
  return this.l[e.type + !0](Yr.event ? Yr.event(e) : e);
}
function Cn(e, t, i, s, r, n, o, a, l, c) {
  var u,
    d,
    h,
    _,
    p,
    g,
    v,
    f,
    m,
    y,
    b,
    w,
    S,
    k,
    E,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), n = [a = t.__e = i.__e]), (u = Yr.__b) && u(t);
  e: if ("function" == typeof x) try {
    if (f = t.props, m = (u = x.contextType) && s[u.__c], y = u ? m ? m.props.value : u.__ : s, i.__c ? v = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(f, y) : (t.__c = d = new gn(f, y), d.constructor = x, d.render = An), m && m.sub(d), d.props = f, d.state || (d.state = {}), d.context = y, d.__n = s, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = dn({}, d.__s)), dn(d.__s, x.getDerivedStateFromProps(f, d.__s))), _ = d.props, p = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && f !== _ && null != d.componentWillReceiveProps && d.componentWillReceiveProps(f, y), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(f, d.__s, y) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = f, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), b = 0; b < d._sb.length; b++) d.__h.push(d._sb[b]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(f, d.__s, y), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(_, p, g);
      });
    }
    if (d.context = y, d.props = f, d.__P = e, d.__e = !1, w = Yr.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), u = d.render(d.props, d.state, d.context), k = 0; k < d._sb.length; k++) d.__h.push(d._sb[k]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), u = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (s = dn(dn({}, s), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (g = d.getSnapshotBeforeUpdate(_, p)), bn(e, un(E = null != u && u.type === pn && null == u.key ? u.props.children : u) ? E : [E], t, i, s, r, n, o, a, l, c), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), v && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != n ? (t.__e = a, t.__u |= l ? 160 : 32, n[n.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), Yr.__e(e, t, i);
  } else null == n && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = Rn(i.__e, t, i, s, r, n, o, l, c);
  (u = Yr.diffed) && u(t);
}
function Fn(e, t, i) {
  t.__d = void 0;
  for (var s = 0; s < i.length; s++) Tn(i[s], i[++s], i[++s]);
  Yr.__c && Yr.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      Yr.__e(e, t.__v);
    }
  });
}
function Rn(e, t, i, s, r, n, o, a, l) {
  var c,
    u,
    d,
    h,
    _,
    p,
    g,
    v = i.props,
    f = t.props,
    m = t.type;
  if ("svg" === m && (r = !0), null != n) for (c = 0; c < n.length; c++) if ((_ = n[c]) && "setAttribute" in _ == !!m && (m ? _.localName === m : 3 === _.nodeType)) {
    e = _, n[c] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(f);
    e = r ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, f.is && f), n = null, a = !1;
  }
  if (null === m) v === f || a && e.data === f || (e.data = f);else {
    if (n = n && Jr.call(e.childNodes), v = i.props || an, !a && null != n) for (v = {}, c = 0; c < e.attributes.length; c++) v[(_ = e.attributes[c]).name] = _.value;
    for (c in v) _ = v[c], "children" == c || ("dangerouslySetInnerHTML" == c ? d = _ : "key" === c || c in f || xn(e, c, null, _, r));
    for (c in f) _ = f[c], "children" == c ? h = _ : "dangerouslySetInnerHTML" == c ? u = _ : "value" == c ? p = _ : "checked" == c ? g = _ : "key" === c || a && "function" != typeof _ || v[c] === _ || xn(e, c, _, v[c], r);
    if (u) a || d && (u.__html === d.__html || u.__html === e.innerHTML) || (e.innerHTML = u.__html), t.__k = [];else if (d && (e.innerHTML = ""), bn(e, un(h) ? h : [h], t, i, s, r && "foreignObject" !== m, n, o, n ? n[0] : i.__k && vn(i, 0), a, l), null != n) for (c = n.length; c--;) null != n[c] && hn(n[c]);
    a || (c = "value", void 0 !== p && (p !== e[c] || "progress" === m && !p || "option" === m && p !== v[c]) && xn(e, c, p, v[c], !1), c = "checked", void 0 !== g && g !== e[c] && xn(e, c, g, v[c], !1));
  }
  return e;
}
function Tn(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    Yr.__e(e, i);
  }
}
function $n(e, t, i) {
  var s, r;
  if (Yr.unmount && Yr.unmount(e), (s = e.ref) && (s.current && s.current !== e.__e || Tn(s, null, t)), null != (s = e.__c)) {
    if (s.componentWillUnmount) try {
      s.componentWillUnmount();
    } catch (e) {
      Yr.__e(e, t);
    }
    s.base = s.__P = null, e.__c = void 0;
  }
  if (s = e.__k) for (r = 0; r < s.length; r++) s[r] && $n(s[r], t, i || "function" != typeof e.type);
  i || null == e.__e || hn(e.__e), e.__ = e.__e = e.__d = void 0;
}
function An(e, t, i) {
  return this.constructor(e, i);
}
Jr = ln.slice, Yr = {
  __e: function (e, t, i, s) {
    for (var r, n, o; t = t.__;) if ((r = t.__c) && !r.__) try {
      if ((n = r.constructor) && null != n.getDerivedStateFromError && (r.setState(n.getDerivedStateFromError(e)), o = r.__d), null != r.componentDidCatch && (r.componentDidCatch(e, s || {}), o = r.__d), o) return r.__E = r;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Kr = 0, gn.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = dn({}, this.state), "function" == typeof e && (e = e(dn({}, i), this.props)), e && dn(i, e), null != e && this.__v && (t && this._sb.push(t), mn(this));
}, gn.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), mn(this));
}, gn.prototype.render = pn, Xr = [], Zr = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, en = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, yn.__r = 0, tn = 0, function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(sn || (sn = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(rn || (rn = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(nn || (nn = {})), function (e) {
  e.Once = "once", e.Recurring = "recurring", e.Always = "always";
}(on || (on = {}));
!function (e, t) {
  var i = {
    __c: t = "__cC" + tn++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, s;
      return this.getChildContext || (i = [], (s = {})[t] = this, this.getChildContext = function () {
        return s;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, mn(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  onPopupSurveyDismissed: () => {},
  isPopup: !0,
  onPreviewSubmit: () => {},
  onPopupSurveySent: () => {}
});
var On = function (e, t) {
  if (!function (e) {
    try {
      new RegExp(e);
    } catch (e) {
      return !1;
    }
    return !0;
  }(t)) return !1;
  try {
    return new RegExp(t).test(e);
  } catch (e) {
    return !1;
  }
};
class Mn {
  constructor() {
    V(this, "events", {}), this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (var i of this.events[e] || []) i(t);
    for (var s of this.events["*"] || []) s(e, t);
  }
}
class Ln {
  constructor(e) {
    V(this, "_debugEventEmitter", new Mn()), V(this, "checkStep", (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t)), V(this, "checkStepEvent", (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event)), this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!F(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.on(e, t);
      });
    }
  }
  register(e) {
    var t, i;
    if (!F(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var s,
        r = new Set();
      e.forEach(e => {
        var t;
        null === (t = e.steps) || void 0 === t || t.forEach(e => {
          null != e && e.selector && r.add(null == e ? void 0 : e.selector);
        });
      }), null === (s = this.instance) || void 0 === s || s.autocapture.setElementSelectors(r);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (var i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i,
        s = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!s || "string" != typeof s) return !1;
      if (!Ln.matchString(s, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(e, i, s) {
    switch (s) {
      case "regex":
        return !!t && On(e, i);
      case "exact":
        return i === e;
      case "contains":
        var r = Ln.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return On(e, r);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if ((null != t && t.href || null != t && t.tag_name || null != t && t.text) && !this.getElementsList(e).some(e => !(null != t && t.href && !Ln.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !Ln.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !Ln.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    if (null != t && t.selector) {
      var i,
        s = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!s) return !1;
      if (!s.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class Dn {
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    F(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    var t = e.filter(e => {
      var t, i, s, r;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (s = i.actions) || void 0 === s || null === (r = s.values) || void 0 === r ? void 0 : r.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new Ln(this.instance), this.actionMatcher.init();
        this.actionMatcher._addActionHook(e => {
          this.onAction(e);
        });
      }
      t.forEach(e => {
        var t, i, s, r, n, o, a, l, c, u;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (s = i.actions) && void 0 !== s && s.values && (null === (r = e.conditions) || void 0 === r || null === (n = r.actions) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c || null === (u = c.values) || void 0 === u || u.forEach(t => {
          if (t && t.name) {
            var i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 !== e.filter(e => {
      var t, i, s, r;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (s = i.events) || void 0 === s || null === (r = s.values) || void 0 === r ? void 0 : r.length) > 0;
    }).length) {
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.onEvent(e, t);
      }), e.forEach(e => {
        var t, i, s;
        null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (s = i.values) || void 0 === s || s.forEach(t => {
          if (t && t.name) {
            var i = this.eventToSurveys.get(t.name);
            i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
          }
        });
      });
    }
  }
  onEvent(e, t) {
    var i,
      s,
      r = (null === (i = this.instance) || void 0 === i || null === (s = i.persistence) || void 0 === s ? void 0 : s.props[Oe]) || [];
    if (Dn.SURVEY_SHOWN_EVENT_NAME == e && t && r.length > 0) {
      var n,
        o = null == t || null === (n = t.properties) || void 0 === n ? void 0 : n.$survey_id;
      if (o) {
        var a = r.indexOf(o);
        a >= 0 && (r.splice(a, 1), this._updateActivatedSurveys(r));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(r.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t,
      i,
      s = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[Oe]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(s.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [Oe]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e,
      t,
      i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[Oe];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
V(Dn, "SURVEY_SHOWN_EVENT_NAME", "survey shown");
var qn = H("[Surveys]"),
  Nn = {
    icontains: (e, t) => e.some(e => t.toLowerCase().includes(e.toLowerCase())),
    not_icontains: (e, t) => e.every(e => !t.toLowerCase().includes(e.toLowerCase())),
    regex: (e, t) => e.some(e => On(t, e)),
    not_regex: (e, t) => e.every(e => !On(t, e)),
    exact: (e, t) => e.some(e => t === e),
    is_not: (e, t) => e.every(e => t !== e)
  };
function Bn(e) {
  return null != e ? e : "icontains";
}
class Hn {
  constructor(e) {
    V(this, "_isFetchingSurveys", !1), V(this, "_isInitializingSurveys", !1), this.instance = e, this._surveyEventReceiver = null;
  }
  onRemoteConfig(e) {
    this._decideServerResponse = !!e.surveys, qn.info("decideServerResponse set to ".concat(this._decideServerResponse)), this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    var e = (() => {
      for (var e = [], t = 0; t < localStorage.length; t++) {
        var i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    if (!this._surveyManager) if (this._isInitializingSurveys) qn.info("Already initializing surveys, skipping...");else if (this.instance.config.disable_surveys) qn.info("Disabled. Not loading surveys.");else {
      var e = null == _ ? void 0 : _.__PosthogExtensions__;
      if (e) {
        if (this._decideServerResponse) {
          this._isInitializingSurveys = !0;
          try {
            var t = e.generateSurveys;
            if (t) this._surveyManager = t(this.instance), this._isInitializingSurveys = !1, this._surveyEventReceiver = new Dn(this.instance), qn.info("Surveys loaded successfully");else {
              var i = e.loadExternalDependency;
              i ? i(this.instance, "surveys", t => {
                if (t || !e.generateSurveys) return qn.error("Could not load surveys script", t), void (this._isInitializingSurveys = !1);
                this._surveyManager = e.generateSurveys(this.instance), this._isInitializingSurveys = !1, this._surveyEventReceiver = new Dn(this.instance), qn.info("Surveys loaded successfully");
              }) : (qn.error("PostHog loadExternalDependency extension not found. Cannot load remote config."), this._isInitializingSurveys = !1);
            }
          } catch (e) {
            throw qn.error("Error initializing surveys", e), this._isInitializingSurveys = !1, e;
          }
        } else qn.warn("Decide not loaded yet. Not loading surveys.");
      } else qn.error("PostHog Extensions not found.");
    }
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return qn.info("Disabled. Not loading surveys."), e([]);
    var i = this.instance.get_property(Ae);
    if (i && !t) return e(i);
    if (this._isFetchingSurveys) return e([]);
    try {
      this._isFetchingSurveys = !0, this.instance._send_request({
        url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
        method: "GET",
        timeout: this.instance.config.surveys_request_timeout_ms,
        callback: t => {
          var i;
          this._isFetchingSurveys = !1;
          var s = t.statusCode;
          if (200 !== s || !t.json) return qn.error("Surveys API could not be loaded, status: ".concat(s)), e([]);
          var r,
            n = t.json.surveys || [],
            o = n.filter(e => {
              var t, i, s, r, n, o, a, l, c, u, d, h;
              return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (s = i.events) || void 0 === s ? void 0 : s.values) && (null === (r = e.conditions) || void 0 === r || null === (n = r.events) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c ? void 0 : c.values) && (null === (u = e.conditions) || void 0 === u || null === (d = u.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
            });
          o.length > 0 && (null === (r = this._surveyEventReceiver) || void 0 === r || r.register(o));
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Ae]: n
          }), e(n);
        }
      });
    } catch (e) {
      throw this._isFetchingSurveys = !1, e;
    }
  }
  isSurveyFeatureFlagEnabled(e) {
    return !e || this.instance.featureFlags.isFeatureEnabled(e);
  }
  getActiveMatchingSurveys(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(i => {
      var s,
        r = i.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var i;
          if (!e.conditions) return !0;
          var s = function (e) {
              var i, s, r;
              if (null === (i = e.conditions) || void 0 === i || !i.url) return !0;
              var n = null == t || null === (s = t.location) || void 0 === s ? void 0 : s.href;
              if (!n) return !1;
              var o = [e.conditions.url];
              return Nn[Bn(null === (r = e.conditions) || void 0 === r ? void 0 : r.urlMatchType)](o, n);
            }(e),
            r = null === (i = e.conditions) || void 0 === i || !i.selector || (null == a ? void 0 : a.querySelector(e.conditions.selector)),
            n = function (e) {
              var t, i, s;
              if (null === (t = e.conditions) || void 0 === t || !t.deviceTypes || 0 === (null === (i = e.conditions) || void 0 === i ? void 0 : i.deviceTypes.length)) return !0;
              if (!h) return !1;
              var r = Wr.deviceType(h);
              return Nn[Bn(null === (s = e.conditions) || void 0 === s ? void 0 : s.deviceTypesMatchType)](e.conditions.deviceTypes, r);
            }(e);
          return s && r && n;
        }),
        n = null === (s = this._surveyEventReceiver) || void 0 === s ? void 0 : s.getSurveys(),
        o = r.filter(e => {
          var t, i, s, r, o, a, l, c, u;
          if (!(e.linked_flag_key || e.targeting_flag_key || e.internal_targeting_flag_key || null !== (t = e.feature_flag_keys) && void 0 !== t && t.length)) return !0;
          var d = this.isSurveyFeatureFlagEnabled(e.linked_flag_key),
            h = this.isSurveyFeatureFlagEnabled(e.targeting_flag_key),
            _ = (null !== (i = null === (s = e.conditions) || void 0 === s || null === (r = s.events) || void 0 === r || null === (o = r.values) || void 0 === o ? void 0 : o.length) && void 0 !== i ? i : 0) > 0,
            p = (null !== (a = null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c || null === (u = c.values) || void 0 === u ? void 0 : u.length) && void 0 !== a ? a : 0) > 0,
            g = !_ && !p || (null == n ? void 0 : n.includes(e.id)),
            v = this._canActivateRepeatedly(e) || this.isSurveyFeatureFlagEnabled(e.internal_targeting_flag_key),
            f = this.checkFlags(e);
          return d && h && v && g && f;
        });
      return e(o);
    }, i);
  }
  checkFlags(e) {
    var t;
    return null === (t = e.feature_flag_keys) || void 0 === t || !t.length || e.feature_flag_keys.every(e => {
      var {
        key: t,
        value: i
      } = e;
      return !t || !i || this.instance.featureFlags.isFeatureEnabled(i);
    });
  }
  _canActivateRepeatedly(e) {
    var t;
    return A(null === (t = _.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? (qn.warn("init was not called"), !1) : _.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    A(this._surveyManager) ? qn.warn("init was not called") : this.getSurveys(t => {
      var i = t.filter(t => t.id === e)[0];
      this._surveyManager.canRenderSurvey(i);
    });
  }
  renderSurvey(e, t) {
    A(this._surveyManager) ? qn.warn("init was not called") : this.getSurveys(i => {
      var s = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(s, null == a ? void 0 : a.querySelector(t));
    });
  }
}
var Un = H("[RateLimiter]");
class zn {
  constructor(e) {
    var t, i;
    V(this, "serverLimits", {}), V(this, "lastEventRateLimited", !1), V(this, "checkForLimiting", e => {
      var t = e.text;
      if (t && t.length) try {
        (JSON.parse(t).quota_limited || []).forEach(e => {
          Un.info("".concat(e || "events", " is quota limited.")), this.serverLimits[e] = new Date().getTime() + 6e4;
        });
      } catch (e) {
        return void Un.warn('could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
          text: t
        });
      }
    }), this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e,
      t,
      i,
      s = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      r = new Date().getTime(),
      n = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property(qe)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: r
      };
    n.tokens += (r - n.last) / 1e3 * this.captureEventsPerSecond, n.last = r, n.tokens > this.captureEventsBurstLimit && (n.tokens = this.captureEventsBurstLimit);
    var o = n.tokens < 1;
    return o || s || (n.tokens = Math.max(0, n.tokens - 1)), !o || this.lastEventRateLimited || s || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: "posthog-js client rate limited. Config is set to ".concat(this.captureEventsPerSecond, " events per second and ").concat(this.captureEventsBurstLimit, " events burst limit.")
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property(qe, n), {
      isRateLimited: o,
      remainingTokens: n.tokens
    };
  }
  isServerRateLimited(e) {
    var t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
}
var jn = H("[RemoteConfig]");
class Wn {
  constructor(e) {
    this.instance = e;
  }
  get remoteConfig() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.config;
  }
  _loadRemoteConfigJs(e) {
    var t, i, s;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.loadExternalDependency ? null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "remote-config", () => e(this.remoteConfig)) : (jn.error("PostHog Extensions not found. Cannot load remote config."), e());
  }
  _loadRemoteConfigJSON(e) {
    this.instance._send_request({
      method: "GET",
      url: this.instance.requestRouter.endpointFor("assets", "/array/".concat(this.instance.config.token, "/config")),
      callback: t => {
        e(t.json);
      }
    });
  }
  load() {
    try {
      if (this.remoteConfig) return jn.info("Using preloaded remote config", this.remoteConfig), void this.onRemoteConfig(this.remoteConfig);
      if (this.instance.config.advanced_disable_decide) return void jn.warn("Remote config is disabled. Falling back to local config.");
      this._loadRemoteConfigJs(e => {
        if (!e) return jn.info("No config found after loading remote JS config. Falling back to JSON."), void this._loadRemoteConfigJSON(e => {
          this.onRemoteConfig(e);
        });
        this.onRemoteConfig(e);
      });
    } catch (e) {
      jn.error("Error loading remote config", e);
    }
  }
  onRemoteConfig(e) {
    e ? this.instance.config.__preview_remote_config ? (this.instance._onRemoteConfig(e), !1 !== e.hasFeatureFlags && this.instance.featureFlags.ensureFlagsLoaded()) : jn.info("__preview_remote_config is disabled. Logging config instead", e) : jn.error("Failed to fetch remote config from PostHog.");
  }
}
var Vn = function (e) {
    var t,
      i,
      s,
      r,
      n = "";
    for (t = i = 0, s = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, r = 0; r < s; r++) {
      var o = e.charCodeAt(r),
        a = null;
      o < 128 ? i++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), $(a) || (i > t && (n += e.substring(t, i)), n += a, t = i = r + 1);
    }
    return i > t && (n += e.substring(t, e.length)), n;
  },
  Gn = !!u || !!c,
  Jn = "text/plain",
  Yn = (e, t) => {
    var [i, s] = e.split("?"),
      r = W({}, t);
    null == s || s.split("&").forEach(e => {
      var [t] = e.split("=");
      delete r[t];
    });
    var n = kt(r);
    return n = n ? (s ? s + "&" : "") + n : s, "".concat(i, "?").concat(n);
  },
  Kn = (e, t) => JSON.stringify(e, (e, t) => "bigint" == typeof t ? t.toString() : t, t),
  Xn = t => {
    var {
      data: i,
      compression: s
    } = t;
    if (i) {
      if (s === e.GZipJS) {
        var r = ns(os(Kn(i)), {
            mtime: 0
          }),
          n = new Blob([r], {
            type: Jn
          });
        return {
          contentType: Jn,
          body: n,
          estimatedSize: n.size
        };
      }
      if (s === e.Base64) {
        var o = function (e) {
            var t,
              i,
              s,
              r,
              n,
              o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
              a = 0,
              l = 0,
              c = "",
              u = [];
            if (!e) return e;
            e = Vn(e);
            do {
              t = (n = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, i = n >> 12 & 63, s = n >> 6 & 63, r = 63 & n, u[l++] = o.charAt(t) + o.charAt(i) + o.charAt(s) + o.charAt(r);
            } while (a < e.length);
            switch (c = u.join(""), e.length % 3) {
              case 1:
                c = c.slice(0, -2) + "==";
                break;
              case 2:
                c = c.slice(0, -1) + "=";
            }
            return c;
          }(Kn(i)),
          a = (e => "data=" + encodeURIComponent("string" == typeof e ? e : Kn(e)))(o);
        return {
          contentType: "application/x-www-form-urlencoded",
          body: a,
          estimatedSize: new Blob([a]).size
        };
      }
      var l = Kn(i);
      return {
        contentType: "application/json",
        body: l,
        estimatedSize: new Blob([l]).size
      };
    }
  },
  Qn = [];
c && Qn.push({
  transport: "fetch",
  method: e => {
    var t,
      i,
      {
        contentType: s,
        body: r,
        estimatedSize: n
      } = null !== (t = Xn(e)) && void 0 !== t ? t : {},
      o = new Headers();
    K(e.headers, function (e, t) {
      o.append(t, e);
    }), s && o.append("Content-Type", s);
    var a = e.url,
      l = null;
    if (d) {
      var u = new d();
      l = {
        signal: u.signal,
        timeout: setTimeout(() => u.abort(), e.timeout)
      };
    }
    c(a, W({
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (n || 0) < 52428.8,
      body: r,
      signal: null === (i = l) || void 0 === i ? void 0 : i.signal
    }, e.fetchOptions)).then(t => t.text().then(i => {
      var s,
        r = {
          statusCode: t.status,
          text: i
        };
      if (200 === t.status) try {
        r.json = JSON.parse(i);
      } catch (e) {
        B.error(e);
      }
      null === (s = e.callback) || void 0 === s || s.call(e, r);
    })).catch(t => {
      var i;
      B.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => l ? clearTimeout(l.timeout) : null);
  }
}), u && Qn.push({
  transport: "XHR",
  method: e => {
    var t,
      i = new u();
    i.open(e.method || "GET", e.url, !0);
    var {
      contentType: s,
      body: r
    } = null !== (t = Xn(e)) && void 0 !== t ? t : {};
    K(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), s && i.setRequestHeader("Content-Type", s), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t,
          s = {
            statusCode: i.status,
            text: i.responseText
          };
        if (200 === i.status) try {
          s.json = JSON.parse(i.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, s);
      }
    }, i.send(r);
  }
}), null != o && o.sendBeacon && Qn.push({
  transport: "sendBeacon",
  method: e => {
    var t = Yn(e.url, {
      beacon: "1"
    });
    try {
      var i,
        {
          contentType: s,
          body: r
        } = null !== (i = Xn(e)) && void 0 !== i ? i : {},
        n = "string" == typeof r ? new Blob([r], {
          type: s
        }) : r;
      o.sendBeacon(t, n);
    } catch (e) {}
  }
});
var Zn = 3e3;
class eo {
  constructor(e, t) {
    V(this, "isPaused", !0), V(this, "queue", []), this.flushTimeoutMs = wi((null == t ? void 0 : t.flush_interval_ms) || Zn, 250, 5e3, "flush interval", Zn), this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    var e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest(W(W({}, e), {}, {
        transport: "sendBeacon"
      }));
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    var e = this;
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        var t = this.formatQueue(),
          i = function (i) {
            var s = t[i],
              r = new Date().getTime();
            s.data && x(s.data) && K(s.data, e => {
              e.offset = Math.abs(e.timestamp - r), delete e.timestamp;
            }), e.sendRequest(s);
          };
        for (var s in t) i(s);
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    var e = {};
    return K(this.queue, t => {
      var i,
        s = t,
        r = (s ? s.batchKey : null) || s.url;
      F(e[r]) && (e[r] = W(W({}, s), {}, {
        data: []
      })), null === (i = e[r].data) || void 0 === i || i.push(s.data);
    }), this.queue = [], e;
  }
}
var to = ["retriesPerformedSoFar"];
class io {
  constructor(e) {
    V(this, "isPolling", !1), V(this, "pollIntervalMs", 3e3), V(this, "queue", []), this.instance = e, this.queue = [], this.areWeOnline = !0, !F(t) && "onLine" in t.navigator && (this.areWeOnline = t.navigator.onLine, ae(t, "online", () => {
      this.areWeOnline = !0, this.flush();
    }), ae(t, "offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    var {
        retriesPerformedSoFar: t
      } = e,
      i = G(e, to);
    O(t) && t > 0 && (i.url = Yn(i.url, {
      retry_count: t
    })), this.instance._send_request(W(W({}, i), {}, {
      callback: e => {
        var s;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue(W({
          retriesPerformedSoFar: t
        }, i)) : null === (s = i.callback) || void 0 === s || s.call(i, e);
      }
    }));
  }
  enqueue(e) {
    var t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    var i = function (e) {
        var t = 3e3 * Math.pow(2, e),
          i = t / 2,
          s = Math.min(18e5, t),
          r = (Math.random() - .5) * (s - i);
        return Math.ceil(s + r);
      }(t),
      s = Date.now() + i;
    this.queue.push({
      retryAt: s,
      requestOptions: e
    });
    var r = "Enqueued failed request for retry in ".concat(i);
    navigator.onLine || (r += " (Browser is offline)"), B.warn(r), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    var e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (var {
      requestOptions: s
    } of i) this.retriableRequest(s);
  }
  unload() {
    for (var {
      requestOptions: e
    } of (this.poller && (clearTimeout(this.poller), this.poller = void 0), this.queue)) try {
      this.instance._send_request(W(W({}, e), {}, {
        transport: "sendBeacon"
      }));
    } catch (e) {
      B.error(e);
    }
    this.queue = [];
  }
}
class so {
  constructor(e) {
    V(this, "_updateScrollData", () => {
      var e, t, i, s;
      this.context || (this.context = {});
      var r = this.scrollElement(),
        n = this.scrollY(),
        o = r ? Math.max(0, r.scrollHeight - r.clientHeight) : 0,
        a = n + ((null == r ? void 0 : r.clientHeight) || 0),
        l = (null == r ? void 0 : r.scrollHeight) || 0;
      this.context.lastScrollY = Math.ceil(n), this.context.maxScrollY = Math.max(n, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (s = this.context.maxContentHeight) && void 0 !== s ? s : 0);
    }), this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    var e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  startMeasuringScrollPosition() {
    ae(t, "scroll", this._updateScrollData, {
      capture: !0
    }), ae(t, "scrollend", this._updateScrollData, {
      capture: !0
    }), ae(t, "resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == t ? void 0 : t.document.documentElement;
    var e = x(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
    for (var i of e) {
      var s = null == t ? void 0 : t.document.querySelector(i);
      if (s) return s;
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollTop || 0;
    }
    return t && (t.scrollY || t.pageYOffset || t.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollLeft || 0;
    }
    return t && (t.scrollX || t.pageXOffset || t.document.documentElement.scrollLeft) || 0;
  }
}
var ro = e => Wr.personInfo({
  maskPersonalDataProperties: null == e ? void 0 : e.config.mask_personal_data_properties,
  customPersonalDataProperties: null == e ? void 0 : e.config.custom_personal_data_properties
});
class no {
  constructor(e, t, i, s) {
    V(this, "_onSessionIdCallback", e => {
      var t = this._getStored();
      if (!t || t.sessionId !== e) {
        var i = {
          sessionId: e,
          props: this._sessionSourceParamGenerator(this.instance)
        };
        this._persistence.register({
          [De]: i
        });
      }
    }), this.instance = e, this._sessionIdManager = t, this._persistence = i, this._sessionSourceParamGenerator = s || ro, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStored() {
    return this._persistence.props[De];
  }
  getSetOnceProps() {
    var e,
      t = null === (e = this._getStored()) || void 0 === e ? void 0 : e.props;
    return t ? "r" in t ? Wr.personPropsFromInfo(t) : {
      $referring_domain: t.referringDomain,
      $pathname: t.initialPathName,
      utm_source: t.utm_source,
      utm_campaign: t.utm_campaign,
      utm_medium: t.utm_medium,
      utm_content: t.utm_content,
      utm_term: t.utm_term
    } : {};
  }
  getSessionProps() {
    var e = {};
    return K(ie(this.getSetOnceProps()), (t, i) => {
      "$current_url" === i && (i = "url"), e["$session_entry_".concat(b(i))] = t;
    }), e;
  }
}
var oo = H("[SessionId]");
class ao {
  constructor(e, t, i) {
    var s;
    if (V(this, "_sessionIdChangedHandlers", []), !e.persistence) throw new Error("SessionIdManager requires a PostHogPersistence instance");
    if (e.config.__preview_experimental_cookieless_mode) throw new Error("SessionIdManager cannot be used with __preview_experimental_cookieless_mode");
    this.config = e.config, this.persistence = e.persistence, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = t || Nt, this._windowIdGenerator = i || Nt;
    var r = this.config.persistence_name || this.config.token,
      n = this.config.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * wi(n, 60, 36e3, "session_idle_timeout_seconds", 1800), e.register({
      $configured_session_timeout_ms: this._sessionTimeoutMs
    }), this.resetIdleTimer(), this._window_id_storage_key = "ph_" + r + "_window_id", this._primary_window_exists_storage_key = "ph_" + r + "_primary_window_exists", this._canUseSessionStorage()) {
      var o = ei.parse(this._window_id_storage_key),
        a = ei.parse(this._primary_window_exists_storage_key);
      o && !a ? this._windowId = o : ei.remove(this._window_id_storage_key), ei.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (s = this.config.bootstrap) && void 0 !== s && s.sessionID) try {
      var l = (e => {
        var t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), l);
    } catch (e) {
      oo.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return F(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && ei.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && ei.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? ei.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [xe]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    var e = this.persistence.props[xe];
    return x(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    ae(t, "beforeunload", () => {
      this._canUseSessionStorage() && ei.remove(this._primary_window_exists_storage_key);
    }, {
      capture: !1
    });
  }
  checkAndGetSessionAndWindowId() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
    if (this.config.__preview_experimental_cookieless_mode) throw new Error("checkAndGetSessionAndWindowId should not be called in __preview_experimental_cookieless_mode");
    var i = t || new Date().getTime(),
      [s, r, n] = this._getSessionId(),
      o = this._getWindowId(),
      a = O(n) && n > 0 && Math.abs(i - n) > 864e5,
      l = !1,
      c = !r,
      u = !e && Math.abs(i - s) > this.sessionTimeoutMs;
    c || u || a ? (r = this._sessionIdGenerator(), o = this._windowIdGenerator(), oo.info("new session ID generated", {
      sessionId: r,
      windowId: o,
      changeReason: {
        noSessionId: c,
        activityTimeout: u,
        sessionPastMaximumLength: a
      }
    }), n = i, l = !0) : o || (o = this._windowIdGenerator(), l = !0);
    var d = 0 === s || !e || a ? i : s,
      h = 0 === n ? new Date().getTime() : n;
    return this._setWindowId(o), this._setSessionId(r, d, h), e || this.resetIdleTimer(), l && this._sessionIdChangedHandlers.forEach(e => e(r, o, l ? {
      noSessionId: c,
      activityTimeout: u,
      sessionPastMaximumLength: a
    } : void 0)), {
      sessionId: r,
      windowId: o,
      sessionStartTimestamp: h,
      changeReason: l ? {
        noSessionId: c,
        activityTimeout: u,
        sessionPastMaximumLength: a
      } : void 0,
      lastActivityTimestamp: s
    };
  }
  resetIdleTimer() {
    clearTimeout(this._enforceIdleTimeout), this._enforceIdleTimeout = setTimeout(() => {
      this.resetSessionId();
    }, 1.1 * this.sessionTimeoutMs);
  }
}
var lo = ["$set_once", "$set"],
  co = H("[SiteApps]");
class uo {
  constructor(e) {
    this.instance = e, this.bufferedInvocations = [], this.apps = {};
  }
  get isEnabled() {
    return !!this.instance.config.opt_in_site_apps;
  }
  eventCollector(e, t) {
    if (t) {
      var i = this.globalsForEvent(t);
      this.bufferedInvocations.push(i), this.bufferedInvocations.length > 1e3 && (this.bufferedInvocations = this.bufferedInvocations.slice(10));
    }
  }
  get siteAppLoaders() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.siteApps;
  }
  init() {
    if (this.isEnabled) {
      var e = this.instance._addCaptureHook(this.eventCollector.bind(this));
      this.stopBuffering = () => {
        e(), this.bufferedInvocations = [], this.stopBuffering = void 0;
      };
    }
  }
  globalsForEvent(e) {
    var t, i, s, r, n, o, a;
    if (!e) throw new Error("Event payload is required");
    var l = {},
      c = this.instance.get_property("$groups") || [],
      u = this.instance.get_property("$stored_group_properties") || {};
    for (var [d, h] of Object.entries(u)) l[d] = {
      id: c[d],
      type: d,
      properties: h
    };
    var {
      $set_once: _,
      $set: p
    } = e;
    return {
      event: W(W({}, G(e, lo)), {}, {
        properties: W(W(W({}, e.properties), p ? {
          $set: W(W({}, null !== (t = null === (i = e.properties) || void 0 === i ? void 0 : i.$set) && void 0 !== t ? t : {}), p)
        } : {}), _ ? {
          $set_once: W(W({}, null !== (s = null === (r = e.properties) || void 0 === r ? void 0 : r.$set_once) && void 0 !== s ? s : {}), _)
        } : {}),
        elements_chain: null !== (n = null === (o = e.properties) || void 0 === o ? void 0 : o.$elements_chain) && void 0 !== n ? n : "",
        distinct_id: null === (a = e.properties) || void 0 === a ? void 0 : a.distinct_id
      }),
      person: {
        properties: this.instance.get_property("$stored_person_properties")
      },
      groups: l
    };
  }
  setupSiteApp(e) {
    var t = this.apps[e.id],
      i = () => {
        var i;
        (!t.errored && this.bufferedInvocations.length && (co.info("Processing ".concat(this.bufferedInvocations.length, " events for site app with id ").concat(e.id)), this.bufferedInvocations.forEach(e => {
          var i;
          return null === (i = t.processEvent) || void 0 === i ? void 0 : i.call(t, e);
        }), t.processedBuffer = !0), Object.values(this.apps).every(e => e.processedBuffer || e.errored)) && (null === (i = this.stopBuffering) || void 0 === i || i.call(this));
      },
      s = !1,
      r = r => {
        t.errored = !r, t.loaded = !0, co.info("Site app with id ".concat(e.id, " ").concat(r ? "loaded" : "errored")), s && i();
      };
    try {
      var {
        processEvent: n
      } = e.init({
        posthog: this.instance,
        callback: e => {
          r(e);
        }
      });
      n && (t.processEvent = n), s = !0;
    } catch (t) {
      co.error("Error while initializing PostHog app with config id ".concat(e.id), t), r(!1);
    }
    if (s && t.loaded) try {
      i();
    } catch (i) {
      co.error("Error while processing buffered events PostHog app with config id ".concat(e.id), i), t.errored = !0;
    }
  }
  setupSiteApps() {
    var e = this.siteAppLoaders || [];
    for (var t of e) this.apps[t.id] = {
      id: t.id,
      loaded: !1,
      errored: !1,
      processedBuffer: !1
    };
    for (var i of e) this.setupSiteApp(i);
  }
  onCapturedEvent(e) {
    if (0 !== Object.keys(this.apps).length) {
      var t = this.globalsForEvent(e);
      for (var i of Object.values(this.apps)) try {
        var s;
        null === (s = i.processEvent) || void 0 === s || s.call(i, t);
      } catch (t) {
        co.error("Error while processing event ".concat(e.event, " for site app ").concat(i.id), t);
      }
    }
  }
  onRemoteConfig(e) {
    var t,
      i,
      s,
      r = this;
    if (null !== (t = this.siteAppLoaders) && void 0 !== t && t.length) return this.isEnabled ? (this.setupSiteApps(), void this.instance.on("eventCaptured", e => this.onCapturedEvent(e))) : void co.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
    if (null === (i = this.stopBuffering) || void 0 === i || i.call(this), null !== (s = e.siteApps) && void 0 !== s && s.length) if (this.isEnabled) {
      var n = function (e, t) {
        var i, s;
        _["__$$ph_site_app_".concat(e)] = r.instance, null === (i = _.__PosthogExtensions__) || void 0 === i || null === (s = i.loadSiteApp) || void 0 === s || s.call(i, r.instance, t, t => {
          if (t) return co.error("Error while initializing PostHog app with config id ".concat(e), t);
        });
      };
      for (var {
        id: o,
        url: a
      } of e.siteApps) n(o, a);
    } else co.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
  }
}
var ho,
  _o = ["amazonbot", "amazonproductbot", "app.hypefactors.com", "applebot", "archive.org_bot", "awariobot", "backlinksextendedbot", "baiduspider", "bingbot", "bingpreview", "chrome-lighthouse", "dataforseobot", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "meta-externalagent", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "sebot-wa", "sitebulb", "slackbot", "slurp", "trendictionbot", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "zoombot", "bot.htm", "bot.php", "(bot;", "bot/", "crawler", "ahrefsbot", "ahrefssiteaudit", "semrushbot", "siteauditbot", "splitsignalbot", "gptbot", "oai-searchbot", "chatgpt-user", "perplexitybot", "better uptime bot", "sentryuptimebot", "uptimerobot", "headlesschrome", "cypress", "google-hoteladsverifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "bytespider"],
  po = function (e, t) {
    if (!e) return !1;
    var i = e.toLowerCase();
    return _o.concat(t || []).some(e => {
      var t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  go = function (e, t) {
    if (!e) return !1;
    var i = e.userAgent;
    if (i && po(i, t)) return !0;
    try {
      var s = null == e ? void 0 : e.userAgentData;
      if (null != s && s.brands && s.brands.some(e => po(null == e ? void 0 : e.brand, t))) return !0;
    } catch (e) {}
    return !!e.webdriver;
  };
function vo(e, t, i) {
  return Kn({
    distinct_id: e,
    userPropertiesToSet: t,
    userPropertiesToSetOnce: i
  });
}
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(ho || (ho = {}));
var fo = "i.posthog.com";
class mo {
  constructor(e) {
    V(this, "_regionCache", {}), this.instance = e;
  }
  get apiHost() {
    var e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e,
      t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(".".concat(fo), ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = ho.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = ho.EU : this._regionCache[this.apiHost] = ho.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return this.uiHost + t;
    if (this.region === ho.CUSTOM) return this.apiHost + t;
    var i = fo + t;
    switch (e) {
      case "assets":
        return "https://".concat(this.region, "-assets.").concat(i);
      case "api":
        return "https://".concat(this.region, ".").concat(i);
    }
  }
}
var yo = {
  icontains: (e, i) => !!t && i.href.toLowerCase().indexOf(e.toLowerCase()) > -1,
  not_icontains: (e, i) => !!t && -1 === i.href.toLowerCase().indexOf(e.toLowerCase()),
  regex: (e, i) => !!t && On(i.href, e),
  not_regex: (e, i) => !!t && !On(i.href, e),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class bo {
  constructor(e) {
    var t = this;
    V(this, "getWebExperimentsAndEvaluateDisplayLogic", function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      t.getWebExperiments(e => {
        bo.logInfo("retrieved web experiments from the server"), t._flagToExperiments = new Map(), e.forEach(e => {
          if (e.feature_flag_key) {
            var i;
            if (t._flagToExperiments) bo.logInfo("setting flag key ", e.feature_flag_key, " to web experiment ", e), null === (i = t._flagToExperiments) || void 0 === i || i.set(e.feature_flag_key, e);
            var s = t.instance.getFeatureFlag(e.feature_flag_key);
            R(s) && e.variants[s] && t.applyTransforms(e.name, s, e.variants[s].transforms);
          } else if (e.variants) for (var r in e.variants) {
            var n = e.variants[r];
            bo.matchesTestVariant(n) && t.applyTransforms(e.name, r, n.transforms);
          }
        });
      }, e);
    }), this.instance = e, this.instance.onFeatureFlags(e => {
      this.onFeatureFlags(e);
    });
  }
  onFeatureFlags(e) {
    if (this._is_bot()) bo.logInfo("Refusing to render web experiment since the viewer is a likely bot");else if (!this.instance.config.disable_web_experiments) {
      if (A(this._flagToExperiments)) return this._flagToExperiments = new Map(), this.loadIfEnabled(), void this.previewWebExperiment();
      bo.logInfo("applying feature flags", e), e.forEach(e => {
        var t;
        if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
          var i,
            s = this.instance.getFeatureFlag(e),
            r = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
          s && null != r && r.variants[s] && this.applyTransforms(r.name, s, r.variants[s].transforms);
        }
      });
    }
  }
  previewWebExperiment() {
    var e = bo.getWindowLocation();
    if (null != e && e.search) {
      var t = Et(null == e ? void 0 : e.search, "__experiment_id"),
        i = Et(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (bo.logInfo("previewing web experiments ".concat(t, " && ").concat(i)), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    var s = this.instance.get_property("$web_experiments");
    if (s && !t) return e(s);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/web_experiments/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        var i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    var s = i.filter(t => t.id === e);
    s && s.length > 0 && (bo.logInfo("Previewing web experiment [".concat(s[0].name, "] with variant [").concat(t, "]")), this.applyTransforms(s[0].name, t, s[0].variants[t].transforms));
  }
  static matchesTestVariant(e) {
    return !A(e.conditions) && bo.matchUrlConditions(e) && bo.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (A(e.conditions) || A(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    var i,
      s,
      r,
      n = bo.getWindowLocation();
    return !!n && (null === (i = e.conditions) || void 0 === i || !i.url || yo[null !== (s = null === (r = e.conditions) || void 0 === r ? void 0 : r.urlMatchType) && void 0 !== s ? s : "icontains"](e.conditions.url, n));
  }
  static getWindowLocation() {
    return null == t ? void 0 : t.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (A(e.conditions) || A(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    var i = Wr.campaignParams();
    if (i.utm_source) {
      var s,
        r,
        n,
        o,
        a,
        l,
        c,
        u,
        d,
        h,
        _,
        p,
        g,
        v,
        f,
        m,
        y = null === (s = e.conditions) || void 0 === s || null === (r = s.utm) || void 0 === r || !r.utm_campaign || (null === (n = e.conditions) || void 0 === n || null === (o = n.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        b = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (c = e.conditions) || void 0 === c || null === (u = c.utm) || void 0 === u ? void 0 : u.utm_source) == i.utm_source,
        w = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (_ = e.conditions) || void 0 === _ || null === (p = _.utm) || void 0 === p ? void 0 : p.utm_medium) == i.utm_medium,
        S = null === (g = e.conditions) || void 0 === g || null === (v = g.utm) || void 0 === v || !v.utm_term || (null === (f = e.conditions) || void 0 === f || null === (m = f.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return y && w && S && b;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), s = 1; s < t; s++) i[s - 1] = arguments[s];
    B.info("[WebExperiments] ".concat(e), i);
  }
  applyTransforms(e, t, i) {
    this._is_bot() ? bo.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var s;
        bo.logInfo("applying transform of variant ".concat(t, " for experiment ").concat(e, " "), i);
        var r = null === (s = document) || void 0 === s ? void 0 : s.querySelectorAll(i.selector);
        null == r || r.forEach(e => {
          var t = e;
          i.html && (t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        });
      }
    }) : bo.logInfo("Control variants leave the page unmodified.");
  }
  _is_bot() {
    return o && this.instance ? go(o, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
var wo = {},
  So = () => {},
  ko = "posthog",
  Eo = !Gn && -1 === (null == h ? void 0 : h.indexOf("MSIE")) && -1 === (null == h ? void 0 : h.indexOf("Mozilla")),
  xo = () => {
    var e;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: ne(null == a ? void 0 : a.location),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: So,
      save_campaign_params: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: l && R(null == l ? void 0 : l.search) && -1 !== l.search.indexOf("__posthog_debug=true") || !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      disable_external_dependency_loading: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == t || null === (e = t.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      mask_personal_data_properties: !1,
      custom_personal_data_properties: [],
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      surveys_request_timeout_ms: 1e4,
      on_request_error: e => {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        B.error(t);
      },
      get_device_id: e => e,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      before_send: void 0,
      request_queue_config: {
        flush_interval_ms: Zn
      },
      _onCapture: So
    };
  },
  Io = e => {
    var t = {};
    F(e.process_person) || (t.person_profiles = e.process_person), F(e.xhr_headers) || (t.request_headers = e.xhr_headers), F(e.cookie_name) || (t.persistence_name = e.cookie_name), F(e.disable_cookie) || (t.disable_persistence = e.disable_cookie), F(e.store_google) || (t.save_campaign_params = e.store_google), F(e.verbose) || (t.debug = e.verbose);
    var i = X({}, t, e);
    return x(e.property_blacklist) && (F(e.property_denylist) ? i.property_denylist = e.property_blacklist : x(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : B.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class Po {
  constructor() {
    V(this, "__forceAllowLocalhost", !1);
  }
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    B.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
}
class Co {
  get decideEndpointWasHit() {
    var e, t;
    return null !== (e = null === (t = this.featureFlags) || void 0 === t ? void 0 : t.hasLoadedFlags) && void 0 !== e && e;
  }
  constructor() {
    V(this, "webPerformance", new Po()), V(this, "_personProcessingSetOncePropertiesSent", !1), V(this, "version", p.LIB_VERSION), V(this, "_internalEventEmitter", new Mn()), this.config = xo(), this.SentryIntegration = ws, this.sentryIntegration = e => function (e, t) {
      var i = bs(e, t);
      return {
        name: ys,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this._cachedIdentify = null, this.featureFlags = new Ws(this), this.toolbar = new Is(this), this.scrollManager = new so(this), this.pageViewManager = new Ms(this), this.surveys = new Hn(this), this.experiments = new bo(this), this.exceptions = new Ls(this), this.rateLimiter = new zn(this), this.requestRouter = new mo(this), this.consent = new ti(this), this.people = {
      set: (e, t, i) => {
        var s = R(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(s), null == i || i({});
      },
      set_once: (e, t, i) => {
        var s = R(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, s), null == i || i({});
      }
    }, this.on("eventCaptured", e => B.info('send "'.concat(null == e ? void 0 : e.event, '"'), e));
  }
  init(e, t, i) {
    if (i && i !== ko) {
      var s,
        r = null !== (s = wo[i]) && void 0 !== s ? s : new Co();
      return r._init(e, t, i), wo[i] = r, wo[ko][i] = r, r;
    }
    return this._init(e, t, i);
  }
  _init(i) {
    var s,
      r,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      o = arguments.length > 2 ? arguments[2] : void 0;
    if (F(i) || T(i)) return B.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return B.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], n.person_profiles && (this._initialPersonProfilesConfig = n.person_profiles), this.set_config(X({}, xo(), Io(n), {
      name: o,
      token: i
    })), this.config.on_xhr_error && B.error("on_xhr_error is deprecated. Use on_request_error instead"), this.compression = n.disable_compression ? void 0 : e.GZipJS, this.persistence = new Gr(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Gr(W(W({}, this.config), {}, {
      persistence: "sessionStorage"
    }));
    var a = W({}, this.persistence.props),
      l = W({}, this.sessionPersistence.props);
    if (this._requestQueue = new eo(e => this._send_retriable_request(e), this.config.request_queue_config), this._retryQueue = new io(this), this.__request_queue = [], this.config.__preview_experimental_cookieless_mode || (this.sessionManager = new ao(this), this.sessionPropsManager = new no(this, this.sessionManager, this.persistence)), new Cs(this).startIfEnabledOrStop(), this.siteApps = new uo(this), null === (s = this.siteApps) || void 0 === s || s.init(), this.config.__preview_experimental_cookieless_mode || (this.sessionRecording = new vs(this), this.sessionRecording.startIfEnabledOrStop()), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new $t(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new Os(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new Ts(this), this.exceptionObserver = new ai(this), this.exceptionObserver.startIfEnabled(), this.deadClicksAutocapture = new ni(this, ri), this.deadClicksAutocapture.startIfEnabled(), p.DEBUG = p.DEBUG || this.config.debug, p.DEBUG && B.info("Starting in debug mode", {
      this: this,
      config: n,
      thisC: W({}, this.config),
      p: a,
      s: l
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (r = n.bootstrap) || void 0 === r ? void 0 : r.distinctID)) {
      var c,
        u,
        d = this.config.get_device_id(Nt()),
        h = null !== (c = n.bootstrap) && void 0 !== c && c.isIdentifiedID ? d : n.bootstrap.distinctID;
      this.persistence.set_property(Le, null !== (u = n.bootstrap) && void 0 !== u && u.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: n.bootstrap.distinctID,
        $device_id: h
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var _,
        g,
        v = Object.keys((null === (_ = n.bootstrap) || void 0 === _ ? void 0 : _.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = n.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, s;
          return e[t] = (null === (i = n.bootstrap) || void 0 === i || null === (s = i.featureFlags) || void 0 === s ? void 0 : s[t]) || !1, e;
        }, {}),
        f = Object.keys((null === (g = n.bootstrap) || void 0 === g ? void 0 : g.featureFlagPayloads) || {}).filter(e => v[e]).reduce((e, t) => {
          var i, s, r, o;
          null !== (i = n.bootstrap) && void 0 !== i && null !== (s = i.featureFlagPayloads) && void 0 !== s && s[t] && (e[t] = null === (r = n.bootstrap) || void 0 === r || null === (o = r.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: v,
        featureFlagPayloads: f
      });
    }
    if (this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: je,
      $device_id: null
    }, "");else if (!this.get_distinct_id()) {
      var m = this.config.get_device_id(Nt());
      this.register_once({
        distinct_id: m,
        $device_id: m
      }, ""), this.persistence.set_property(Le, "anonymous");
    }
    return ae(t, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this), {
      passive: !1
    }), this.toolbar.maybeLoadToolbar(), n.segment ? ms(this, () => this._loaded()) : this._loaded(), I(this.config._onCapture) && this.config._onCapture !== So && (B.warn("onCapture is deprecated. Please use `before_send` instead"), this.on("eventCaptured", e => this.config._onCapture(e.event, e))), this;
  }
  _onRemoteConfig(t) {
    var i, s, r, n, o, l, c, u;
    if (!a || !a.body) return B.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
      this._onRemoteConfig(t);
    }, 500);
    this.compression = void 0, t.supportedCompression && !this.config.disable_compression && (this.compression = m(t.supportedCompression, e.GZipJS) ? e.GZipJS : m(t.supportedCompression, e.Base64) ? e.Base64 : void 0), null !== (i = t.analytics) && void 0 !== i && i.endpoint && (this.analyticsDefaultEndpoint = t.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : "identified_only"
    }), null === (s = this.siteApps) || void 0 === s || s.onRemoteConfig(t), null === (r = this.sessionRecording) || void 0 === r || r.onRemoteConfig(t), null === (n = this.autocapture) || void 0 === n || n.onRemoteConfig(t), null === (o = this.heatmaps) || void 0 === o || o.onRemoteConfig(t), this.surveys.onRemoteConfig(t), null === (l = this.webVitalsAutocapture) || void 0 === l || l.onRemoteConfig(t), null === (c = this.exceptionObserver) || void 0 === c || c.onRemoteConfig(t), null === (u = this.deadClicksAutocapture) || void 0 === u || u.onRemoteConfig(t);
  }
  _loaded() {
    try {
      this.config.loaded(this);
    } catch (e) {
      B.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), new Wn(this).load(), this.featureFlags.decide();
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || Y(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (Eo ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = Yn(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = W({}, this.config.request_headers), e.compression = "best-available" === e.compression ? this.compression : e.compression, e.fetchOptions = e.fetchOptions || this.config.fetch_options, (e => {
      var t,
        i,
        s,
        r = W({}, e);
      r.timeout = r.timeout || 6e4, r.url = Yn(r.url, {
        _: new Date().getTime().toString(),
        ver: p.LIB_VERSION,
        compression: r.compression
      });
      var n = null !== (t = r.transport) && void 0 !== t ? t : "fetch",
        o = null !== (i = null === (s = oe(Qn, e => e.transport === n)) || void 0 === s ? void 0 : s.method) && void 0 !== i ? i : Qn[0].method;
      if (!o) throw new Error("No available transport method");
      o(r);
    })(W(W({}, e), {}, {
      callback: t => {
        var i, s, r;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (s = (r = this.config).on_request_error) || void 0 === s || s.call(r, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    }))));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    var t,
      i = [],
      s = [],
      r = [];
    Y(e, e => {
      e && (t = e[0], x(t) ? r.push(e) : I(e) ? e.call(this) : x(e) && "alias" === t ? i.push(e) : x(e) && -1 !== t.indexOf("capture") && I(this[t]) ? r.push(e) : s.push(e));
    });
    var n = function (e, t) {
      Y(e, function (e) {
        if (x(e[0])) {
          var i = t;
          K(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    n(i, this), n(s, this), n(r, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var s;
    if (this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue) {
      if (!this.consent.isOptedOut()) if (!F(e) && R(e)) {
        if (this.config.opt_out_useragent_filter || !this._is_bot()) {
          var r = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
          if (null == r || !r.isRateLimited) {
            this.sessionPersistence.update_search_keyword(), this.config.save_campaign_params && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.save_campaign_params || this.config.save_referrer) && this.persistence.set_initial_person_info();
            var n = new Date(),
              o = (null == i ? void 0 : i.timestamp) || n,
              a = Nt(),
              l = {
                uuid: a,
                event: e,
                properties: this._calculate_event_properties(e, t || {}, o, a)
              };
            r && (l.properties.$lib_rate_limit_remaining_tokens = r.remainingTokens), (null == i ? void 0 : i.$set) && (l.$set = null == i ? void 0 : i.$set);
            var c = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            c && (l.$set_once = c), (l = se(l, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = o, F(null == i ? void 0 : i.timestamp) || (l.properties.$event_time_override_provided = !0, l.properties.$event_time_override_system_time = n);
            var u = W(W({}, l.properties.$set), l.$set);
            if (C(u) || this.setPersonPropertiesForFlags(u), !A(this.config.before_send)) {
              var d = this._runBeforeSend(l);
              if (!d) return;
              l = d;
            }
            this._internalEventEmitter.emit("eventCaptured", l);
            var h = {
              method: "POST",
              url: null !== (s = null == i ? void 0 : i._url) && void 0 !== s ? s : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: l,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(h) : this._requestQueue.enqueue(h), l;
          }
          B.critical("This capture call is ignored due to client rate limiting.");
        }
      } else B.error("No event name provided to posthog.capture");
    } else B.uninitializedWarning("posthog.capture");
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i, s) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    var r = this.persistence.remove_event_timer(e),
      n = W({}, t);
    if (n.token = this.config.token, this.config.__preview_experimental_cookieless_mode && (n.$cookieless_mode = !0), "$snapshot" === e) {
      var o = W(W({}, this.persistence.properties()), this.sessionPersistence.properties());
      return n.distinct_id = o.distinct_id, (!R(n.distinct_id) && !O(n.distinct_id) || T(n.distinct_id)) && B.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), n;
    }
    var l,
      c = Wr.properties({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
    if (this.sessionManager) {
      var {
        sessionId: u,
        windowId: d
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      n.$session_id = u, n.$window_id = d;
    }
    this.sessionPropsManager && X(n, this.sessionPropsManager.getSessionProps());
    try {
      var _, p;
      this.sessionRecording && (n.$recording_status = this.sessionRecording.status, n.$sdk_debug_replay_internal_buffer_length = this.sessionRecording.buffer.data.length, n.$sdk_debug_replay_internal_buffer_size = this.sessionRecording.buffer.size), n.$sdk_debug_retry_queue_size = null === (_ = this._retryQueue) || void 0 === _ || null === (p = _.queue) || void 0 === p ? void 0 : p.length;
    } catch (e) {
      n.$sdk_debug_error_capturing_properties = String(e);
    }
    if (this.requestRouter.region === ho.CUSTOM && (n.$lib_custom_api_host = this.config.api_host), l = "$pageview" === e ? this.pageViewManager.doPageView(i, s) : "$pageleave" === e ? this.pageViewManager.doPageLeave(i) : this.pageViewManager.doEvent(), n = X(n, l), "$pageview" === e && a && (n.title = a.title), !F(r)) {
      var g = i.getTime() - r;
      n.$duration = parseFloat((g / 1e3).toFixed(3));
    }
    h && this.config.opt_out_useragent_filter && (n.$browser_type = this._is_bot() ? "bot" : "browser"), (n = X({}, c, this.persistence.properties(), this.sessionPersistence.properties(), n)).$is_identified = this._isIdentified(), x(this.config.property_denylist) ? K(this.config.property_denylist, function (e) {
      delete n[e];
    }) : B.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    var v = this.config.sanitize_properties;
    v && (B.error("sanitize_properties is deprecated. Use before_send instead"), n = v(n, e));
    var f = this._hasPersonProcessing();
    return n.$process_person_profile = f, f && this._requirePersonProcessing("_calculate_event_properties"), n;
  }
  _calculate_set_once_properties(e) {
    var t;
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    if (this._personProcessingSetOncePropertiesSent) return e;
    var i = this.persistence.get_initial_props(),
      s = null === (t = this.sessionPropsManager) || void 0 === t ? void 0 : t.getSetOnceProps(),
      r = X({}, i, s || {}, e || {}),
      n = this.config.sanitize_properties;
    return n && (B.error("sanitize_properties is deprecated. Use before_send instead"), r = n(r, "$set_once")), this._personProcessingSetOncePropertiesSent = !0, C(r) ? void 0 : r;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var s;
    null === (s = this.persistence) || void 0 === s || s.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    var t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch (e) {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return this.featureFlags.getEarlyAccessFeatures(e, t);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    this.surveys.canRenderSurvey(e);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return B.uninitializedWarning("posthog.identify");
    if (O(e) && (e = e.toString(), B.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
      if (["distinct_id", "distinctid"].includes(e.toLowerCase())) B.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
        var s = this.get_distinct_id();
        if (this.register({
          $user_id: e
        }), !this.get_property("$device_id")) {
          var r = s;
          this.register_once({
            $had_persisted_distinct_id: !0,
            $device_id: r
          }, "");
        }
        e !== s && e !== this.get_property(ce) && (this.unregister(ce), this.register({
          distinct_id: e
        }));
        var n = "anonymous" === (this.persistence.get_property(Le) || "anonymous");
        e !== s && n ? (this.persistence.set_property(Le, "identified"), this.setPersonPropertiesForFlags(W(W({}, i || {}), t || {}), !1), this.capture("$identify", {
          distinct_id: e,
          $anon_distinct_id: s
        }, {
          $set: t || {},
          $set_once: i || {}
        }), this.featureFlags.setAnonymousDistinctId(s), this._cachedIdentify = vo(e, t, i)) : (t || i) && (this._cachedIdentify !== vo(e, t, i) ? (this.setPersonProperties(t, i), this._cachedIdentify = vo(e, t, i)) : B.info("A duplicate posthog.identify call was made with the same properties. It has been ignored.")), e !== s && (this.reloadFeatureFlags(), this.unregister(Me));
      }
    } else B.error("Unique user id has not been set in posthog.identify");
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(W(W({}, t || {}), e || {})), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (e && t) {
      if (this._requirePersonProcessing("posthog.group")) {
        var s = this.getGroups();
        s[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
          $groups: W(W({}, s), {}, {
            [e]: t
          })
        }), i && (this.capture("$groupidentify", {
          $group_type: e,
          $group_key: t,
          $group_set: i
        }), this.setGroupPropertiesForFlags({
          [e]: i
        })), s[e] === t || i || this.reloadFeatureFlags();
      }
    } else B.error("posthog.group requires a group type and group key");
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, s, r;
    if (B.info("reset"), !this.__loaded) return B.uninitializedWarning("posthog.reset");
    var n = this.get_property("$device_id");
    if (this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), this.surveys.reset(), null === (s = this.persistence) || void 0 === s || s.set_property(Le, "anonymous"), null === (r = this.sessionManager) || void 0 === r || r.resetSessionId(), this._cachedIdentify = null, this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: je,
      $device_id: null
    }, "");else {
      var o = this.config.get_device_id(Nt());
      this.register_once({
        distinct_id: o,
        $device_id: e ? o : n
      }, "");
    }
    this.register({
      $last_posthog_reset: new Date().toISOString()
    }, 1);
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    var {
        sessionId: t,
        sessionStartTimestamp: i
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0),
      s = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(t));
    if (null != e && e.withTimestamp && i) {
      var r,
        n = null !== (r = e.timestampLookBack) && void 0 !== r ? r : 10;
      if (!i) return s;
      var o = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - n, 0);
      s += "?t=".concat(o);
    }
    return s;
  }
  alias(e, t) {
    return e === this.get_property(le) ? (B.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (F(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(ce, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : (B.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    var t,
      i,
      s,
      r,
      n = W({}, this.config);
    P(e) && (X(this.config, Io(e)), null === (t = this.persistence) || void 0 === t || t.update_config(this.config, n), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Gr(W(W({}, this.config), {}, {
      persistence: "sessionStorage"
    })), Jt.is_supported() && "true" === Jt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (p.DEBUG = !0, B.info("set_config", {
      config: e,
      oldConfig: n,
      newConfig: W({}, this.config)
    })), null === (i = this.sessionRecording) || void 0 === i || i.startIfEnabledOrStop(), null === (s = this.autocapture) || void 0 === s || s.startIfEnabled(), null === (r = this.heatmaps) || void 0 === r || r.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    var t = !0 === e,
      i = {
        sampling: t || !(null == e || !e.sampling),
        linked_flag: t || !(null == e || !e.linked_flag),
        url_trigger: t || !(null == e || !e.url_trigger),
        event_trigger: t || !(null == e || !e.event_trigger)
      };
    if (Object.values(i).some(Boolean)) {
      var s, r, n, o, a;
      if (null === (s = this.sessionManager) || void 0 === s || s.checkAndGetSessionAndWindowId(), i.sampling) null === (r = this.sessionRecording) || void 0 === r || r.overrideSampling();
      if (i.linked_flag) null === (n = this.sessionRecording) || void 0 === n || n.overrideLinkedFlag();
      if (i.url_trigger) null === (o = this.sessionRecording) || void 0 === o || o.overrideTrigger("url");
      if (i.event_trigger) null === (a = this.sessionRecording) || void 0 === a || a.overrideTrigger("event");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i,
      s = new Error("PostHog syntheticException"),
      r = I(null === (i = _.__PosthogExtensions__) || void 0 === i ? void 0 : i.parseErrorAsProperties) ? W(W({}, _.__PosthogExtensions__.parseErrorAsProperties(D(e) ? {
        error: e,
        event: e.message
      } : {
        event: e
      }, {
        syntheticException: s
      })), t) : W({
        $exception_level: "error",
        $exception_list: [{
          type: D(e) ? e.name : "Error",
          value: D(e) ? e.message : e,
          mechanism: {
            handled: !0,
            synthetic: !1
          }
        }]
      }, t);
    this.exceptions.sendExceptionEvent(r);
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e,
      t = null !== (e = this.config.name) && void 0 !== e ? e : ko;
    return t !== ko && (t = ko + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(Le)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(Le));
  }
  _hasPersonProcessing() {
    var e, t, i, s;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && C(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[ce]) && (null === (i = this.persistence) || void 0 === i || null === (s = i.props) || void 0 === s || !s[Ue]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? (B.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(Ue, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e,
      t,
      i,
      s,
      r = this.consent.isOptedOut(),
      n = this.config.opt_out_persistence_by_default,
      o = this.config.disable_persistence || r && !!n;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== o && (null === (i = this.persistence) || void 0 === i || i.set_disabled(o));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== o && (null === (s = this.sessionPersistence) || void 0 === s || s.set_disabled(o));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), F(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return o ? go(o, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    a && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: a.title
    }, {
      send_instantly: !0
    }));
  }
  debug(e) {
    !1 === e ? (null == t || t.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == t || t.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
  _runBeforeSend(e) {
    if (A(this.config.before_send)) return e;
    var t = x(this.config.before_send) ? this.config.before_send : [this.config.before_send],
      i = e;
    for (var s of t) {
      if (i = s(i), A(i)) {
        var r = "Event '".concat(e.event, "' was rejected in beforeSend function");
        return q(e.event) ? B.warn("".concat(r, ". This can cause unexpected behavior.")) : B.info(r), null;
      }
      i.properties && !C(i.properties) || B.warn("Event '".concat(e.event, "' has no properties after beforeSend function, this is likely an error."));
    }
    return i;
  }
  getPageViewId() {
    var e;
    return null === (e = this.pageViewManager._currentPageview) || void 0 === e ? void 0 : e.pageViewId;
  }
  captureTraceFeedback(e, t) {
    this.capture("$ai_feedback", {
      $ai_trace_id: String(e),
      $ai_feedback_text: t
    });
  }
  captureTraceMetric(e, t, i) {
    this.capture("$ai_metric", {
      $ai_trace_id: String(e),
      $ai_metric_name: t,
      $ai_metric_value: String(i)
    });
  }
}
!function (e, t) {
  for (var i = 0; i < t.length; i++) e.prototype[t[i]] = te(e.prototype[t[i]]);
}(Co, ["identify"]);
var Fo,
  Ro = (Fo = wo[ko] = new Co(), function () {
    function e() {
      e.done || (e.done = !0, Eo = !1, K(wo, function (e) {
        e._dom_loaded();
      }));
    }
    null != a && a.addEventListener ? "complete" === a.readyState ? e() : ae(a, "DOMContentLoaded", e, {
      capture: !1
    }) : t && B.error("Browser doesn't support `document.addEventListener` so PostHog couldn't be initialized");
  }(), Fo);
export { g as COPY_AUTOCAPTURE_EVENT, e as Compression, Co as PostHog, nn as SurveyQuestionBranchingType, rn as SurveyQuestionType, on as SurveySchedule, sn as SurveyType, Ro as default, v as knownUnsafeEditableEvent, Ro as posthog, f as severityLevels };
